import {
    LOAD_USER_PROFLE_SUCCESS,
    LOAD_USER_PROFLE_FAIL,
    UPDATE_USER_PROFLE_SUCCESS,
    UPDATE_USER_PROFLE_FAIL,
} from '../actions/types'

const initialState = {
    user_name: '',
    first_name: '',
    last_name: '',
    company: '',
    role: '',
    estate: '',
    employee_id: '',
    
}

export default function(state=initialState, action){
    const {type, payload} = action

    switch(type){
        case LOAD_USER_PROFLE_SUCCESS:
        case UPDATE_USER_PROFLE_SUCCESS:
            return{
                ...state,
                user_name: payload.user_name,
                first_name: payload.profile.first_name,
                last_name: payload.profile.last_name,
                company: payload.profile.company,
                role: payload.profile.role,
                estate: payload.profile.estate,
                employee_id: payload.profile.employee_id,
                user: payload.profile.user
            }
        case LOAD_USER_PROFLE_FAIL:
            return{
                ...state,
                user_name: '',
                first_name: '',
                last_name: '',
                company: '',
                role:'',
                estate: '',
                employee_id: ''
            }

        case UPDATE_USER_PROFLE_FAIL:
            return{
                ...state
            }
        default:
            return state
    }
}

