import Cookies from 'js-cookie'
import axios from 'axios'
import {
    INPUT_DATA_UPDATE_SUCCESS,
    INPUT_DATA_UPDATE_FAIL,
    UPDATE_DATA_SUCCESS,
    UPDATE_DATA_FAIL,
    DELETE_DATA_SUCCESS,
    DELETE_DATA_FAIL
} from './types'
import { toast } from 'react-toastify'


export const add_input_data = (
    DO_NO,
    Date,
    Plantation,
    Division,
    Parts,
    Block,
    Mill,
    Clerk,
    Driver,
    Vehicle_No,
    TimeIN,
    TimeOUT,
    First_Weight,
    Second_Weight,
    Today_NetWeight,
    Remarks
) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
    }

    const body = JSON.stringify({
        // 'withCredentials': true,
        DO_NO,
        Date,
        Plantation,
        Division,
        Parts,
        Block,
        Mill,
        Clerk,
        Driver,
        Vehicle_No,
        TimeIN,
        TimeOUT,
        First_Weight,
        Second_Weight,
        Today_NetWeight,
        Remarks
    })

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/LTSB/LTSBffbrlog/`, body, config)

        if (!res.data.DO_NO) {
            dispatch({
                type: INPUT_DATA_UPDATE_FAIL,
            })
            toast(res.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else if (res.data.DO_NO) {
            dispatch({
                type: INPUT_DATA_UPDATE_SUCCESS,
                payload: res.data
            })
            toast(res.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        } else {
            dispatch({
                type: INPUT_DATA_UPDATE_FAIL,
            })
        }
    } catch (err) {
        dispatch({
            type: INPUT_DATA_UPDATE_FAIL
        })
    }
}


export const update_data = (
    DO_NO,
    Date,
    Plantation,
    Division,
    Parts,
    Block,
    Mill,
    Clerk,
    Driver,
    Vehicle_No,
    TimeIN,
    TimeOUT,
    First_Weight,
    Second_Weight,
    Today_NetWeight,
    Remarks) => async dispatch => {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            }
        }

        const body = JSON.stringify({
            'withCredentials': true,
            DO_NO,
            Date,
            Plantation,
            Division,
            Parts,
            Block,
            Mill,
            Clerk,
            Driver,
            Vehicle_No,
            TimeIN,
            TimeOUT,
            First_Weight,
            Second_Weight,
            Today_NetWeight,
            Remarks
        })


        try {
            const res = await axios.put(`${process.env.REACT_APP_API_URL}/LTSB/LTSBffbrlog/DO_NO=${DO_NO}`, body, config)

            if (res) {
                dispatch({
                    type: UPDATE_DATA_SUCCESS,
                    payload: res.data
                })
                toast(res.data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            } else {
                dispatch({
                    type: UPDATE_DATA_FAIL
                })
            }
        } catch (err) {
            dispatch({
                type: UPDATE_DATA_FAIL
            })
        }
    }

export const delete_data = (
    DO_NO,
    Date,
    Plantation,
    Division,
    Parts,
    Block,
    Mill,
    Clerk,
    Driver,
    Vehicle_No,
    TimeIN,
    TimeOUT,
    First_Weight,
    Second_Weight,
    Today_NetWeight,
) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
    }

    const body = JSON.stringify({
        'withCredentials': true,
        DO_NO,
        Date,
        Plantation,
        Division,
        Parts,
        Block,
        Mill,
        Clerk,
        Driver,
        Vehicle_No,
        TimeIN,
        TimeOUT,
        First_Weight,
        Second_Weight,
        Today_NetWeight,
    })

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/LTSB/LTSBffbrlog/DO_NO=${DO_NO}`, config, body)

        if (res) {
            dispatch({
                type: DELETE_DATA_SUCCESS
            })
            toast(res.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        } else {
            dispatch({
                type: DELETE_DATA_FAIL
            })
        }
    } catch (err) {
        dispatch({
            type: DELETE_DATA_FAIL
        })
    }
}