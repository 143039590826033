import {combineReducers} from 'redux'
import auth from './auth'
import profile from './profile'
import inputData from './inputData'
import employeeForm from './employeeForm'
import estimateForm from './estimateForm'
import passwordReset from './passwordReset'

export default combineReducers({
    auth,
    profile,
    inputData,
    employeeForm,
    estimateForm,
    passwordReset,
})