import React, {useState} from 'react'
import { Table } from 'react-bootstrap'
import { Scrollbars } from 'react-custom-scrollbars-2';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import faker from 'faker';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip
);

function MachineryList() {

    const [runningLogChart, setRunningLogChart] = useState(true)
    const [breakLogChart, setBreakLogChart] = useState(false)

    const [first, setFirst] = useState()


    const label = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    const chart_data = {
        labels: label,
        datasets: [
            {
                type: 'line',
                label: 'Dataset 1',
                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 2,
                fill: false,
                data: label.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            },
            {
                type: 'bar',
                label: 'Dataset 2',
                backgroundColor: 'rgb(75, 192, 192)',
                data: label.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                borderColor: 'white',
                borderWidth: 2,
            },
        ]
    }

    const chart_data_break = {
        labels: label,
        datasets: [
            {
                type: 'line',
                label: 'Dataset 1',
                borderColor: 'rgb(255, 31, 132)',
                borderWidth: 2,
                fill: false,
                data: label.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            },
            {
                type: 'bar',
                label: 'Dataset 2',
                backgroundColor: 'rgb(190, 192, 192)',
                data: label.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                borderColor: 'white',
                borderWidth: 2,
            },
        ]
    }

    const showRunningLog = e => {
        setRunningLogChart(true)
        setBreakLogChart(false)
      }

    const showBreakLog = e => {
        setBreakLogChart(true)
        setRunningLogChart(false)
    }

    return (
        <div className='container-fluid mt-5 content-container p-5'>
            <div className='container-fluid row main-list text-center justify-content-center p'>
                <div className='col p-3 '>
                    <p>Date</p>
                    <p>12/6/2022</p>
                </div>
                <div className='col p-3'>
                    <div>
                        <p>Plate Number</p>
                        <p>ASDW1234</p>
                    </div>
                </div>
                <div className='col p-3'>
                    <p>Driver</p>
                    <p>Siamang Gagap</p>
                </div>
            </div>

            <div className='container-fluid border h-75 w-75 my-5 p-5'>
                <div className='row justify-content-center'>
                    <div className='col-3 machinery-log-chart-option border' onClick={(e) => showRunningLog(e)}>
                        <p className='text-center'>Running Log</p>
                    </div>
                    <div className='col-3 machinery-log-chart-option border' onClick={(e) => showBreakLog(e)}>
                        <p className='text-center'>Breakdown Log</p>
                    </div>
                </div>
                <div className='h-100 w-100 p-5 border'>
                    <div className='running-log-hide'>
                        <Chart type='bar' data={chart_data} />
                    </div>
                    <div className={breakLogChart}>
                        <Chart type='bar' data={chart_data} />
                    </div>

                </div>
            </div>


            <div className='container-fluid table-list-machinery p-5 mt-5'>
                <div className='row'>
                    <div className='col-6'>
                        <h4 className='mb-4'>Running Log</h4>
                        <div className='running-log-table'>
                            <Scrollbars style={{ width: "100%", height: 500 }}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Status</th>
                                            <th>Driver</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1/2/2021</td>
                                            <td>Running</td>
                                            <td>Otto</td>
                                        </tr>
                                        <tr>
                                            <td>1/2/2021</td>
                                            <td>Running</td>
                                            <td>Otto</td>
                                        </tr>
                                        <tr>
                                            <td>1/2/2021</td>
                                            <td>Running</td>
                                            <td>Otto</td>
                                        </tr>
                                        <tr>
                                            <td>1/2/2021</td>
                                            <td>Running</td>
                                            <td>Otto</td>
                                        </tr>
                                        <tr>
                                            <td>1/2/2021</td>
                                            <td>Running</td>
                                            <td>Otto</td>
                                        </tr>
                                        <tr>
                                            <td>1/2/2021</td>
                                            <td>Running</td>
                                            <td>Otto</td>
                                        </tr>
                                        <tr>
                                            <td>1/2/2021</td>
                                            <td>Running</td>
                                            <td>Otto</td>
                                        </tr>
                                        <tr>
                                            <td>1/2/2021</td>
                                            <td>Running</td>
                                            <td>Otto</td>
                                        </tr>
                                        <tr>
                                            <td>1/2/2021</td>
                                            <td>Running</td>
                                            <td>Otto</td>
                                        </tr>
                                    </tbody>
                                </Table>

                            </Scrollbars>
                        </div>
                    </div>
                    <div className='col-6'>
                        <h4 className='mb-4'>Breakdown Log</h4>
                        <div className='running-log-table'>
                            <Scrollbars style={{ width: "100%", height: 500 }}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Status</th>
                                            <th>Driver</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1/2/2021</td>
                                            <td>Running</td>
                                            <td>Otto</td>
                                        </tr>
                                        <tr>
                                            <td>1/2/2021</td>
                                            <td>Running</td>
                                            <td>Otto</td>
                                        </tr>
                                        <tr>
                                            <td>1/2/2021</td>
                                            <td>Running</td>
                                            <td>Otto</td>
                                        </tr>
                                        <tr>
                                            <td>1/2/2021</td>
                                            <td>Running</td>
                                            <td>Otto</td>
                                        </tr>
                                        <tr>
                                            <td>1/2/2021</td>
                                            <td>Running</td>
                                            <td>Otto</td>
                                        </tr>
                                        <tr>
                                            <td>1/2/2021</td>
                                            <td>Running</td>
                                            <td>Otto</td>
                                        </tr>
                                        <tr>
                                            <td>1/2/2021</td>
                                            <td>Running</td>
                                            <td>Otto</td>
                                        </tr>
                                        <tr>
                                            <td>1/2/2021</td>
                                            <td>Running</td>
                                            <td>Otto</td>
                                        </tr>
                                        <tr>
                                            <td>1/2/2021</td>
                                            <td>Running</td>
                                            <td>Otto</td>
                                        </tr>
                                    </tbody>
                                </Table>

                            </Scrollbars>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MachineryList