import Cookies from 'js-cookie'
import axios from 'axios'
import {
    EST_DATA_SUCCESS,
    EST_DATA_FAIL,
    EST_UPDATE_SUCCESS,
    EST_UPDATE_FAIL,
    EST_DELETE_SUCCESS,
    EST_DELETE_FAIL,
} from './types'
import { toast } from 'react-toastify'


export const add_est_data = (
    Plantation_Name,
    Division_Name,
    Year,
    Month,
    Estimate_Day,
    Hectare
) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
    }

    const body = JSON.stringify({
        'withCredentials': true,
        Plantation_Name,
        Division_Name,
        Year,
        Month,
        Estimate_Day,
        Hectare
    })

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/LTSB/LTSBdivEstNew/`, body, config)

        if (!res) {
            dispatch({
                type: EST_DATA_FAIL,
            })
            toast(res.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else if (res) {
            dispatch({
                type: EST_DATA_SUCCESS,
                payload: res.data
            })
            toast(res.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        } else {
            dispatch({
                type: EST_DATA_FAIL,
            })
        }
    } catch (err) {
        dispatch({
            type: EST_DATA_FAIL
        })
    }
}

export const update_est_data = (
    id,
    Plantation_Name,
    Division_Name,
    Year,
    Month,
    Estimate_Day,
    Hectare
) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
    }

    const body = JSON.stringify({
        'withCredentials': true,
        id,
        Plantation_Name,
        Division_Name,
        Year,
        Month,
        Estimate_Day,
        Hectare
    })


    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/LTSB/LTSBdivEstNew/id=${id}/`, body, config)

        if (res) {
            dispatch({
                type: EST_UPDATE_FAIL,
                payload: res.data
            })
            toast(res.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        } else {
            dispatch({
                type: EST_UPDATE_SUCCESS
            })
        }
    } catch (err) {
        dispatch({
            type: EST_UPDATE_FAIL
        })
    }
}

export const est_delete_data = (
    id,
    Plantation_Name,
    Division_Name,
    Year,
    Month,
    Estimate_Day,
    Hectare
) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
    }

    const body = JSON.stringify({
        'withCredentials': true,
        id,
        Plantation_Name,
        Division_Name,
        Year,
        Month,
        Estimate_Day,
        Hectare
    })

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/LTSB/LTSBdivEstNew/id=${id}/`, config, body)

        if (res) {
            dispatch({
                type: EST_DELETE_SUCCESS
            })
            toast(res.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        } else {
            dispatch({
                type: EST_DELETE_FAIL
            })
        }
    } catch (err) {
        dispatch({
            type: EST_DELETE_FAIL
        })
    }
}