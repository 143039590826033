import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { add_employee_data } from '../actions/employeeForm'
import axios from 'axios'
import { ToastContainer } from 'react-toastify'
import '../../node_modules/react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom'

function AddEmployeeData({
  add_employee_data,
  first_name_global,
  last_name_global,
  company_global,
  role_global,
  estate_global,
  employee_id_global
}) {

  const [employeeId, setEmployeeId] = useState('')
  const [employeeName, setEmployeeName] = useState('')
  const [employeeRole, setemployeeRole] = useState('')
  const [employeePlantation, setEmployeePlantation] = useState('TIARA 1')


  function handleIdChange(event) {
    setEmployeeId(event.target.value)
  }

  function handleNameChange(event) {
    setEmployeeName(event.target.value)
  }

  function handleRoleChange(event) {
    setemployeeRole(event.target.value)
  }
  function handlePlanteChange(event) {
    setEmployeePlantation(event.target.value)
  }

  const onSubmit = e => {
    e.preventDefault();
    add_employee_data(employeeId, employeeName, employeeRole, employeePlantation)
    setEmployeeId('')
    setEmployeeName('')
    setemployeeRole('')
    handlePlanteChange('')
    setTimeout(function () {
      if (role_global === 'Assistant Manager') {
        navigate('/emp_am')
      } else if (role_global === 'Manager') {
        navigate('/emp_m')
      } else if (role_global === 'Clerk') {
        navigate('/emp')
      }
  }, 4000);
  }

  let navigate = useNavigate()

  const cancel = e => {
    if (role_global === 'Assistant Manager') {
      e.preventDefault();
      navigate('/emp_am')
    } else if (role_global === 'Manager') {
      e.preventDefault();
      navigate('/emp_m')
    } else if (role_global === 'Clerk') {
      e.preventDefault();
      navigate('/emp')
    }

  }

  console.log(employeePlantation)

  return (
    <div className="container mt-3">
      <form onSubmit={e => onSubmit(e)}>
        <div className="w-75 mx-auto shadow p-5">
          <h2 className="text-center mb-4">Add Employee</h2>

          <div className="form-group">
            <label className='form-label mt-3' htmlFor='weight'>Employee ID</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Employee ID"
              name="employee_Id"
              value={employeeId}
              onChange={handleIdChange}
            />
          </div>

          <div className="form-group">
            <label className='form-label mt-3' htmlFor='weight'>Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Name"
              name="employee_name"
              value={employeeName}
              onChange={handleNameChange}
            />
          </div>

          <div className="form-group">
            <label className='form-label mt-3' htmlFor='weight'>Role</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Role"
              name="employee_role"
              value={employeeRole}
              onChange={handleRoleChange}
            />
          </div>
          <div className="form-group">
            <label className='form-label mt-3' htmlFor='weight'>Plantation</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Role"
              name="employee_plant"
              value={employeePlantation}
              onChange={handlePlanteChange}
            />
          </div>

          <button className="btn btn-primary btn-block mt-3" type='submit'>Add Data</button>
          <button className="btn btn-dark btn-block mt-3 mx-3" onClick={e => cancel(e)}>Cancel</button>

        </div>
      </form>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

const mapStateToProps = state => ({
  first_name_global: state.profile.first_name,
  last_name_global: state.profile.last_name,
  company_global: state.profile.company,
  role_global: state.profile.role,
  estate_global: state.profile.estate,
  employee_id_global: state.profile.employee_id
})

export default connect(mapStateToProps, { add_employee_data, })(AddEmployeeData)