import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Table } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Doughnut, Line, Chart, PolarArea } from 'react-chartjs-2'
ChartJS.register(...registerables);

function Summary() {

    const [getDatas, setGetDatas] = useState([])
    const [searchPlantation, setSearchPlantation] = useState([])
    const [empList, setEmpList] = useState([])

    //Mill Summary
    const [millSummary, setMillSummary] = useState([])
    const [millSummaryByPlant, setMillSummaryByPlant] = useState([])

    //Driver Summary
    const [empSummary, setEmpSummary] = useState([])
    const [empSummaryByPlant, setEmpSummaryByPlant] = useState([])

    const [getDatasLineGraph, setGetDatasLineGraph] = useState([])
    const [getDatasAllPlant, setGetDatasAllPlant] = useState([])
    const [plotEstimateAll, setPlotEstimateAll] = useState([])
    const [plotEstimate, setPlotEstimate] = useState([])
    const [dailyAnalysis, setDailyAnalysis] = useState([])
    const [dailyAnalysisAll, setDailyAnalysisAll] = useState([])
    const [quarterAnalysis, setQuarterAnalysis] = useState([])
    const [quarterAnalysisAll, setQuarterAnalysisAll] = useState([])
    const [quarterLastYear, setQuarterLastYear] = useState([])
    const [quarterLastYearAll, setQuarterLastYearAll] = useState([])
    const [quarterTableSection, setQuarterTableSection] = useState([])
    const [quarterTableSectionAll, setQuarterTableSectionAll] = useState([])
    const [yearlyGraphCurrent, setYearlyGraphCurrent] = useState([])
    const [yearkyGraphCurrentAll, setYearkyGraphCurrentAll] = useState([])
    const [yearlyGraphLast, setYearlyGraphLast] = useState([])
    const [yearlyGraphLastAll, setYearlyGraphLastAll] = useState([])
    const [yearlySummary, setYearlySummary] = useState([])
    const [yearlySummaryAll, setYearlySummaryAll] = useState([])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBreport/`)
            .then((response) => {
                setGetDatasLineGraph(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBreportall/`)
            .then((response) => {
                setGetDatasAllPlant(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBdivision/`)
            .then((response) => {
                setGetDatas(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBlistplant/`)
            .then((response) => {
                setSearchPlantation(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBlistemp/`)
            .then((response) => {
                setEmpList(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBmill/`)
            .then((response) => {
                setMillSummary(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBmilldetail/`)
            .then((response) => {
                setMillSummaryByPlant(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])



    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBdriver/`)
            .then((response) => {
                setEmpSummary(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBdriverdetail/`)
            .then((response) => {
                setEmpSummaryByPlant(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])



    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBestplotall/`)
            .then((response) => {
                setPlotEstimateAll(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBestplot/`)
            .then((response) => {
                setPlotEstimate(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBdailyanalysis/`)
            .then((response) => {
                setDailyAnalysis(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBtotaldailyanalysis/`)
            .then((response) => {
                setDailyAnalysisAll(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBmonthlyreport/`)
            .then((response) => {
                setQuarterAnalysis(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBtotalmonthlyreport/`)
            .then((response) => {
                setQuarterAnalysisAll(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBmonthlyanalysis/`)
            .then((response) => {
                setQuarterLastYear(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBtotalmonthlyanalysis/`)
            .then((response) => {
                setQuarterLastYearAll(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBqreport/`)
            .then((response) => {
                setQuarterTableSection(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBtotalqreport/`)
            .then((response) => {
                setQuarterTableSectionAll(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBmonthlyreport/`)
            .then((response) => {
                setYearlyGraphCurrent(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBtotalmonthlyreport/`)
            .then((response) => {
                setYearkyGraphCurrentAll(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBmonthlyanalysis/`)
            .then((response) => {
                setYearlyGraphLast(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBtotalmonthlyanalysis/`)
            .then((response) => {
                setYearlyGraphLastAll(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSByearlyreport/`)
            .then((response) => {
                setYearlySummary(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBtotalyearlyreport/`)
            .then((response) => {
                setYearlySummaryAll(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    const todayDate = new Date()

    const getCurrentYear = todayDate.getFullYear()

    const currentDate = new Date()
    const currentYear = moment(currentDate).year() - 4
    const currentYearFive = moment(currentDate).year()
    let yearRange = []

    for (let i = currentYear; i <= currentYearFive; i++) {
        const element = i;
        yearRange.push(element)
    }

    const [searchDate, setSearchDate] = useState(todayDate)
    const [selectedPlantation, setSelectedPlantation] = useState('All')
    const [selectedType, setSelectedType] = useState('Month')
    const [selectedQuarter, setSelectedQuarter] = useState('1Q')
    const [selectedYear, setSelectedYear] = useState(getCurrentYear)

    function handleSearchPlantationChange(event) {
        setSelectedPlantation(event.target.value)
    }

    function handleSearchTypeChange(event) {
        setSelectedType(event.target.value)
    }

    function handleSearchQuarterChange(event) {
        setSelectedQuarter(event.target.value)
    }

    function handleSelectedYearChange(event) {
        setSelectedYear(event.target.value)
    }

    const getMonth = moment(searchDate).month() + 1
    const getYear = moment(searchDate).year()

    var months = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];

    var months_short = ["Jan", "Feb", "Mar", "Apr", "May", "June",
        "July", "Aug", "Sep", "Oct", "Nov", "Dec"];



    const dataDoughnutChart = {
        maintainAspectRatio: false,
        responsive: false,
        labels: filterGraphDoughtnutLabel(selectedPlantation),
        datasets: [
            {
                data: filterGraphDoughtnutWeight(selectedPlantation),
                backgroundColor: ["#F386AA", "#DA92BF", "#A99CCB", "#7EAADA", "#5FB8E8", "#3DC3DC", "#52C0A5", "#69C07B", "#99B75C", "#D2A758", "#F79464", "#F58A7F"],
            }
        ],
    };

    const test3 = new Date(getYear, getMonth, 0).getDate();

    function testDate1(x) {
        const getDate = new Date(x.Date);
        const getMonth = getDate.getMonth()
        return getMonth
    }

    function testDate2(x) {
        const getDate = new Date(x.Date);
        const getYear = getDate.getFullYear()
        return getYear
    }

    function filterGraphLineActualDailyData(filterEstate) {
        if (filterEstate === 'All') {
            const filter = getDatasAllPlant.filter(data => testDate1(data) === getMonth && testDate2(data) === getYear).sort((a, b) => {
                return new Date(a.Date) -
                    new Date(b.Date)
            }).map(x => x.Today_Actual)
            return filter
        } else {
            const filter = getDatasLineGraph.filter(data => testDate1(data) === getMonth && testDate2(data) === getYear && data.Plantation.includes(filterEstate)).sort((a, b) => a.Date - b.Date).sort((a, b) => {
                return new Date(a.Date) -
                    new Date(b.Date)
            }).map(x => x.Today_Actual)
            return filter
        }
    }

    function filterGraphLineActualData(filterEstate) {
        if (filterEstate === 'All') {
            const filter = getDatasAllPlant.filter(data => testDate1(data) === getMonth && testDate2(data) === getYear).sort((a, b) => {
                return new Date(a.Date) -
                    new Date(b.Date)
            }).map(x => x.Todate_Actual)
            return filter
        } else {
            const filter = getDatasLineGraph.filter(data => testDate1(data) === getMonth && testDate2(data) === getYear && data.Plantation.includes(filterEstate)).sort((a, b) => {
                return new Date(a.Date) -
                    new Date(b.Date)
            }).map(x => x.Todate_Actual)
            return filter
        }
    }

    function filterBarGraphTodateEstimate(filterEstate) {
        if (filterEstate === 'All') {
            const estCurrent = plotEstimateAll.filter(data => data.Month === getMonth && data.Year === getYear).map(x => x.Today_Estimate.toFixed(2))
            return estCurrent
        } else {
            const estCurrent = plotEstimate.filter(data => data.Month === getMonth && data.Year === getYear && data.Plantation_Name_id.includes(filterEstate)).map(x => x.Today_Estimate.toFixed(2))
            return estCurrent
        }
    }

    function filterGraphLineActualDailyDataTable(filterEstate) {
        if (filterEstate === 'All') {
            const filter = getDatasAllPlant.filter(data => testDate1(data) === getMonth && testDate2(data) === getYear).sort((a, b) => {
                return new Date(a.Date) -
                    new Date(b.Date)
            })
            return filter
        } else {
            const filter = getDatasLineGraph.filter(data => testDate1(data) === getMonth && testDate2(data) === getYear && data.Plantation.includes(filterEstate)).sort((a, b) => a.Date - b.Date).sort((a, b) => {
                return new Date(a.Date) -
                    new Date(b.Date)
            })
            return filter
        }
    }


    function testDate1(x) {
        const getDate = new Date(x.Date);
        const getMonth = getDate.getMonth() + 1
        return getMonth
    }

    function testDate2(x) {
        const getDate = new Date(x.Date);
        const getYear = getDate.getFullYear()
        return getYear
    }

    function dailyLogger() {
        if (selectedPlantation === 'All') {
            const dailyAllPlant = dailyAnalysisAll.filter(x => testDate1(x) === getMonth && testDate2(x) === getYear)
            return dailyAllPlant
        } else {
            const daily = dailyAnalysis.filter(x => testDate1(x) === getMonth && testDate2(x) === getYear && x.Plantation.includes(selectedPlantation))
            return daily
        }
    }

    function quarterGraph(selectedPlantation) {
        if (selectedPlantation === 'All' && selectedQuarter === '1Q') {
            const quarterGraphAll = quarterAnalysisAll.filter(x => x.Year === Number(selectedYear) && x.Month >= 1 && x.Month <= 3).sort((a, b) => a.Month - b.Month)
            return quarterGraphAll
        } else if (selectedPlantation === 'All' && selectedQuarter === '2Q') {
            const quarterGraphAll = quarterAnalysisAll.filter(x => x.Year === Number(selectedYear) && x.Month >= 4 && x.Month <= 6).sort((a, b) => a.Month - b.Month)
            return quarterGraphAll
        } else if (selectedPlantation === 'All' && selectedQuarter === '3Q') {
            const quarterGraphAll = quarterAnalysisAll.filter(x => x.Year === Number(selectedYear) && x.Month >= 7 && x.Month <= 9).sort((a, b) => a.Month - b.Month)
            return quarterGraphAll
        } else if (selectedPlantation === 'All' && selectedQuarter === '4Q') {
            const quarterGraphAll = quarterAnalysisAll.filter(x => x.Year === Number(selectedYear) && x.Month >= 10 && x.Month <= 12).sort((a, b) => a.Month - b.Month)
            return quarterGraphAll
        } else if (selectedPlantation !== 'All' && selectedQuarter === '1Q') {
            const quarterGraphAll = quarterAnalysis.filter(x => x.Year === Number(selectedYear) && x.Month >= 1 && x.Month <= 3 && x.Plantation.includes(selectedPlantation)).sort((a, b) => a.Month - b.Month)
            return quarterGraphAll
        } else if (selectedPlantation !== 'All' && selectedQuarter === '2Q') {
            const quarterGraphAll = quarterAnalysis.filter(x => x.Year === Number(selectedYear) && x.Month >= 4 && x.Month <= 6 && x.Plantation.includes(selectedPlantation)).sort((a, b) => a.Month - b.Month)
            return quarterGraphAll
        } else if (selectedPlantation !== 'All' && selectedQuarter === '3Q') {
            const quarterGraphAll = quarterAnalysis.filter(x => x.Year === Number(selectedYear) && x.Month >= 7 && x.Month <= 9 && x.Plantation.includes(selectedPlantation)).sort((a, b) => a.Month - b.Month)
            return quarterGraphAll
        } else if (selectedPlantation !== 'All' && selectedQuarter === '4Q') {
            const quarterGraphAll = quarterAnalysis.filter(x => x.Year === Number(selectedYear) && x.Month >= 10 && x.Month <= 12 && x.Plantation.includes(selectedPlantation)).sort((a, b) => a.Month - b.Month)
            return quarterGraphAll
        }
    }

    function quarterGraphLastYear(selectedPlantation) {
        if (selectedPlantation === 'All' && selectedQuarter === '1Q') {
            const quarterGraphAll = quarterLastYearAll.filter(x => x.Year === Number(selectedYear) && x.Month >= 1 && x.Month <= 3).sort((a, b) => a.Month - b.Month)
            return quarterGraphAll
        } else if (selectedPlantation === 'All' && selectedQuarter === '2Q') {
            const quarterGraphAll = quarterLastYearAll.filter(x => x.Year === Number(selectedYear) && x.Month >= 4 && x.Month <= 6).sort((a, b) => a.Month - b.Month)
            return quarterGraphAll
        } else if (selectedPlantation === 'All' && selectedQuarter === '3Q') {
            const quarterGraphAll = quarterLastYearAll.filter(x => x.Year === Number(selectedYear) && x.Month >= 7 && x.Month <= 9).sort((a, b) => a.Month - b.Month)
            return quarterGraphAll
        } else if (selectedPlantation === 'All' && selectedQuarter === '4Q') {
            const quarterGraphAll = quarterLastYearAll.filter(x => x.Year === Number(selectedYear) && x.Month >= 10 && x.Month <= 12).sort((a, b) => a.Month - b.Month)
            return quarterGraphAll
        } else if (selectedPlantation !== 'All' && selectedQuarter === '1Q') {
            const quarterGraphAll = quarterLastYear.filter(x => x.Year === Number(selectedYear) && x.Month >= 1 && x.Month <= 3 && x.Plantation.includes(selectedPlantation)).sort((a, b) => a.Month - b.Month)
            return quarterGraphAll
        } else if (selectedPlantation !== 'All' && selectedQuarter === '2Q') {
            const quarterGraphAll = quarterLastYear.filter(x => x.Year === Number(selectedYear) && x.Month >= 4 && x.Month <= 6 && x.Plantation.includes(selectedPlantation)).sort((a, b) => a.Month - b.Month)
            return quarterGraphAll
        } else if (selectedPlantation !== 'All' && selectedQuarter === '3Q') {
            const quarterGraphAll = quarterLastYear.filter(x => x.Year === Number(selectedYear) && x.Month >= 7 && x.Month <= 9 && x.Plantation.includes(selectedPlantation)).sort((a, b) => a.Month - b.Month)
            return quarterGraphAll
        } else if (selectedPlantation !== 'All' && selectedQuarter === '4Q') {
            const quarterGraphAll = quarterLastYear.filter(x => x.Year === Number(selectedYear) && x.Month >= 10 && x.Month <= 12 && x.Plantation.includes(selectedPlantation)).sort((a, b) => a.Month - b.Month)
            return quarterGraphAll
        }
    }

    function quarterSummary(selectedPlantation) {
        if (selectedPlantation === 'All') {
            const quarterTableAll = quarterTableSectionAll.filter(x => x.Year === Number(selectedYear) && x.Quarter === selectedQuarter).sort((a, b) => a.Quarter - b.Quarter)
            return quarterTableAll
        } else {
            const quarterTable = quarterTableSection.filter(x => x.Year === Number(selectedYear) && x.Plantation.includes(selectedPlantation) && x.Quarter === selectedQuarter).sort((a, b) => a.Quarter - b.Quarter)
            return quarterTable
        }
    }

    function quarterLogSummary(selectedPlantation) {
        if (selectedPlantation === 'All' && selectedQuarter === '1Q') {
            const quarterLog = quarterAnalysisAll.filter(x => x.Year === Number(selectedYear) && x.Month >= 1 && x.Month <= 3).sort((a, b) => a.Month - b.Month)
            return quarterLog
        } else if (selectedPlantation === 'All' && selectedQuarter === '2Q') {
            const quarterLog = quarterAnalysisAll.filter(x => x.Year === Number(selectedYear) && x.Month >= 4 && x.Month <= 6).sort((a, b) => a.Month - b.Month)
            return quarterLog
        } else if (selectedPlantation === 'All' && selectedQuarter === '3Q') {
            const quarterLog = quarterAnalysisAll.filter(x => x.Year === Number(selectedYear) && x.Month >= 7 && x.Month <= 9).sort((a, b) => a.Month - b.Month)
            return quarterLog
        } else if (selectedPlantation === 'All' && selectedQuarter === '4Q') {
            const quarterLog = quarterAnalysisAll.filter(x => x.Year === Number(selectedYear) && x.Month >= 10 && x.Month <= 12).sort((a, b) => a.Month - b.Month)
            return quarterLog
        } else if (selectedPlantation !== 'All' && selectedQuarter === '1Q') {
            const quarterLog = quarterAnalysis.filter(x => x.Year === Number(selectedYear) && x.Month >= 1 && x.Month <= 3 && x.Plantation.includes(selectedPlantation)).sort((a, b) => a.Month - b.Month)
            return quarterLog
        } else if (selectedPlantation !== 'All' && selectedQuarter === '2Q') {
            const quarterLog = quarterAnalysis.filter(x => x.Year === Number(selectedYear) && x.Month >= 4 && x.Month <= 6 && x.Plantation.includes(selectedPlantation)).sort((a, b) => a.Month - b.Month)
            return quarterLog
        } else if (selectedPlantation !== 'All' && selectedQuarter === '3Q') {
            const quarterLog = quarterAnalysis.filter(x => x.Year === Number(selectedYear) && x.Month >= 7 && x.Month <= 9 && x.Plantation.includes(selectedPlantation)).sort((a, b) => a.Month - b.Month)
            return quarterLog
        } else if (selectedPlantation !== 'All' && selectedQuarter === '4Q') {
            const quarterLog = quarterAnalysis.filter(x => x.Year === Number(selectedYear) && x.Month >= 10 && x.Month <= 12 && x.Plantation.includes(selectedPlantation)).sort((a, b) => a.Month - b.Month)
            return quarterLog
        }
    }

    function yearlyGraph(selectedPlantation) {
        if (selectedPlantation === 'All') {
            const yearGraphAll = yearkyGraphCurrentAll.filter(x => x.Year === Number(selectedYear)).sort((a, b) => a.Month - b.Month)
            return yearGraphAll
        } else {
            const yearGraph = yearlyGraphCurrent.filter(x => x.Year === Number(selectedYear) && x.Plantation.includes(selectedPlantation)).sort((a, b) => a.Month - b.Month)
            return yearGraph
        }
    }

    function yearlyGraphLastYear(selectedPlantation) {
        if (selectedPlantation === 'All') {
            const yearGraphAllLast = yearlyGraphLastAll.filter(x => x.Year === Number(selectedYear)).sort((a, b) => a.Month - b.Month)
            return yearGraphAllLast
        } else {
            const yearGraphLast = yearlyGraphLast.filter(x => x.Year === Number(selectedYear) && x.Plantation.includes(selectedPlantation)).sort((a, b) => a.Month - b.Month)
            return yearGraphLast
        }
    }

    function yearlySummaryTable(selectedPlantation) {
        if (selectedPlantation === 'All') {
            const yearlySumm = yearlySummaryAll.filter(x => x.Year === Number(selectedYear))
            return yearlySumm
        } else {
            const yearlySumm = yearlySummary.filter(x => x.Year === Number(selectedYear) && x.Plantation.includes(selectedPlantation))
            return yearlySumm
        }
    }


    function driveSummary(selectedPlantation) {
        if (selectedPlantation === 'All') {
            const driverSum = empSummary.filter(x => x.Year === Number(selectedYear) && x.Month === getMonth)
            return driverSum
        } else {
            const driverSum = empSummaryByPlant.filter(x => x.Year === Number(selectedYear) && x.Month === getMonth && x.Plantation.includes(selectedPlantation))
            return driverSum
        }
    }

    function millingSummary(selectedPlantation) {
        if (selectedPlantation === 'All') {
            const millSum = millSummary.filter(x => x.Year === Number(selectedYear) && x.Month === getMonth)
            return millSum
        } else {
            const millSum = millSummaryByPlant.filter(x => x.Year === Number(selectedYear) && x.Month === getMonth && x.Plantation.includes(selectedPlantation))
            return millSum
        }
    }


    function yieldSummary(selectedPlantation) {
        if (selectedPlantation === 'All') {
            const allData = getDatas.filter(data => data.Month === getMonth && data.Year === getYear).sort((a, b) => {
                return a.Plantation.localeCompare(b.Plantation)
            })
            return allData
        } else {
            const filteredData = getDatas.filter(data => data.Month === getMonth && data.Year === getYear && data.Plantation === selectedPlantation).sort((a, b) => {
                return a.Division.localeCompare(b.Division)
            })

            return filteredData
        }
    }

    function yieldSummaryTotalYPH(selectedPlantation) {
        if (selectedPlantation === 'All') {
            const allData = getDatas.filter(data => data.Month === getMonth && data.Year === getYear).sort((a, b) => {
                return a.Plantation.localeCompare(b.Plantation)
            })
            const totalAllHectare = allData.reduce((total, currentItem) => total = total + currentItem.Hectare, 0);
            const totalAllTodate = allData.reduce((total, currentItem) => total = total + currentItem.Todate_Actual, 0);
            const newTotalAllYPH = totalAllTodate / totalAllHectare

            return [totalAllHectare, totalAllTodate, newTotalAllYPH]
        } else {
            const filteredData = getDatas.filter(data => data.Month === getMonth && data.Year === getYear && data.Plantation === selectedPlantation).sort((a, b) => {
                return a.Division.localeCompare(b.Division)
            })
            const totalFilteredHectare = filteredData.reduce((total, currentItem) => total = total + currentItem.Hectare, 0);
            const totalFilteredTodate = filteredData.reduce((total, currentItem) => total = total + currentItem.Todate_Actual, 0);
            const newTotalFilteredYPH = totalFilteredTodate / totalFilteredHectare

            return [totalFilteredHectare, totalFilteredTodate, newTotalFilteredYPH]
        }
    }

    function filterGraphDoughtnutLabel(selectedPlantation) {
        if (selectedPlantation === 'All') {
            const allDataDiv = getDatas.filter(data => data.Month === getMonth && data.Year === getYear).sort((a, b) => {
                return a.Plantation.localeCompare(b.Plantation)
            }).map(x => x.Division)
            const allDataPlant = getDatas.filter(data => data.Month === getMonth && data.Year === getYear).sort((a, b) => {
                return a.Plantation.localeCompare(b.Plantation)
            }).map(x => x.Plantation)


            const combinedArray = allDataDiv.map((currentDiv, index, arr) => {
                return `${currentDiv} (${allDataPlant[index]})`
            })

            console.log(combinedArray)

            return combinedArray
        } else {
            const filteredData = getDatas.filter(data => data.Month === getMonth && data.Year === getYear && data.Plantation === selectedPlantation).sort((a, b) => {
                return a.Division.localeCompare(b.Division)
            }).map(x => x.Division)
            return filteredData
        }
    }


    const ayam = 'ayam'
    const itik = 'itik'
    const asd = `${ayam} (${itik})`
    console.log(asd)

    function filterGraphDoughtnutWeight(selectedPlantation) {
        if (selectedPlantation === 'All') {
            const allData = getDatas.filter(data => data.Month === getMonth && data.Year === getYear).sort((a, b) => {
                return a.Plantation.localeCompare(b.Plantation)
            }).map(x => x.Todate_Actual)
            return allData
        } else {
            const filteredData = getDatas.filter(data => data.Month === getMonth && data.Year === getYear && data.Plantation === selectedPlantation).sort((a, b) => {
                return a.Division.localeCompare(b.Division)
            }).map(x => x.Todate_Actual)
            return filteredData
        }
    }

    let array = []
    let arrayDay = []

    for (let i = 1; i <= test3; i++) {
        const t = i * filterBarGraphTodateEstimate(selectedPlantation)
        array.push(t)
        arrayDay.push(i)
    }

    const optionsLineChart = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: true,
        },
        scales: {
            y: {
                title: {
                    display: true,
                    text: 'Metric Tonne (Mt)',
                    font: {
                        size: 20,
                    }
                }
            },
            x: {
                title: {
                    display: true,
                    text: 'Day',
                    font: {
                        size: 20,
                    }
                }
            }
        },
        plugins: {
            tooltip: {
                backgroundColor: 'white',
                borderColor: 'rgb(128,128,128)',
                borderWidth: 2,
                titleColor: 'black',
                titleAlign: 'center',
                displayColor: true,
                boxWidth: 0,
                boxHeight: 0,
                bodyAlign: 'center',
                usePointStyle: true,
                bodyFontFamily: 'Arial',
                bodyFontSize: 50,
                bodySpacing: 2,
                padding: 30,
                callbacks: {
                    title: function (item, everything) {
                        return
                    },
                    labelTextColor: function (tooltipItem, chart) {
                        return state1.datasets.borderColor;
                    },
                    label: function (item, everything) {

                        let label = item.dataset.label
                        return label
                    },
                    afterLabel: function (item, everything) {
                        let value = item.formattedValue
                        return value + " Mt"
                    },
                },
                bodyFont: {
                    size: 15,
                    padding: 15,
                },
            }
        }
    };

    const optionPolarChart = {
        responsive: true,
        plugins: {
            tooltip: {
                borderWidth: 2,
                boxWidth: 0,
                boxHeight: 0,
                bodyAlign: 'center',
                usePointStyle: true,
                bodyFontFamily: 'Arial',
                bodyFontSize: 50,
                bodySpacing: 2,
                padding: 30,
                callbacks: {
                    label: function (item, everything) {

                        let label = item.label
                        console.log(item)
                        return label
                    },
                    afterLabel: function (item, everything) {
                        let value = item.formattedValue
                        return value + " Mt"
                    },
                },
                bodyFont: {
                    size: 15,
                    padding: 15,
                },
            }
        }
    }


    const optionDoughnutChart = {
        responsive: true,
        elements: {
            arc: {
                borderWidth: 0
            }
        },
        maintainAspectRatio: false,
        cutout: 50,

        plugins: {
            title: {
                display: true,
                text: 'Division Summary',
                font: { size: 20 },
            },

            tooltip: {
                borderWidth: 2,
                boxWidth: 0,
                boxHeight: 0,
                bodyAlign: 'center',
                usePointStyle: true,
                bodyFontFamily: 'Arial',
                bodyFontSize: 30,
                bodySpacing: 2,
                padding: 10,
                callbacks: {
                    label: function (item, everything) {

                        let label = item.label
                        console.log(item)
                        return label
                    },
                    afterLabel: function (item, everything) {
                        let value = item.formattedValue
                        return value + " Mt"
                    },

                },
                bodyFont: {
                    size: 15,
                    padding: 15,
                },
            },
        }
    }



    const state1 = {
        labels: arrayDay,
        datasets: [
            {
                label: 'Budget FFB Production',
                backgroundColor: 'rgba(67,196,240)',
                lineTension: 0.1,
                borderColor: 'rgba(67,196,240)',
                order: 3,
                data: array
            },
            {
                label: 'Daily FFB Production',
                backgroundColor: 'rgba(255,166,0)',
                lineTension: 0.1,
                borderColor: 'rgba(255,166,0)',
                order: 1,
                data: filterGraphLineActualDailyData(selectedPlantation)

            },
            {
                label: 'Cumulative FFB Production',
                backgroundColor: 'rgba(240,111,237)',
                lineTension: 0.1,
                borderColor: 'rgba(240,111,237)',
                order: 2,
                data: filterGraphLineActualData(selectedPlantation)

            },
        ],
    }

    const labels = quarterGraph(selectedPlantation).map(x => months[x.Month - 1])

    const quarterChart = {
        labels,
        datasets: [
            {
                type: 'line',
                label: 'Budget',
                borderColor: 'rgba(255,0,0)',
                backgroundColor: 'rgba(255,0,0)',
                borderWidth: 2,
                fill: false,
                data: quarterGraph(selectedPlantation).map(x => x.Month_Estimate),
            },
            {
                type: 'bar',
                label: 'Current Year',
                backgroundColor: 'rgba(255,168,0)',
                data: quarterGraph(selectedPlantation).map(x => x.Month_Actual),
                borderColor: 'white',
                borderWidth: 2,
            },
            {
                type: 'bar',
                label: 'Last Year',
                backgroundColor: 'rgba(0,102,255)',
                data: quarterGraphLastYear(selectedPlantation).map(x => x.Last_MonthACT),
            },
        ],
    };

    const quarterChartPolar = {
        labels,
        datasets: [{
            label: 'My First Dataset',
            data: quarterGraph(selectedPlantation).map(x => x.Month_Actual),
            backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(75, 192, 192)',
                'rgb(255, 205, 86)',
                'rgb(201, 203, 207)',
                'rgb(54, 162, 235)'
            ]
        }]
    };

    const labelYear = yearlyGraph(selectedPlantation).map(x => months_short[x.Month - 1])

    console.log(selectedPlantation)

    const yearlyChart = {
        labels: labelYear,
        datasets: [
            {
                type: 'line',
                label: 'Budget',
                borderColor: 'rgba(255,0,0)',
                backgroundColor: 'rgba(255,0,0)',
                borderWidth: 2,
                fill: false,
                data: yearlyGraph(selectedPlantation).map(x => x.Month_Estimate),
            },
            {
                type: 'bar',
                label: 'Current Year',
                backgroundColor: 'rgba(255,168,0)',
                data: yearlyGraph(selectedPlantation).map(x => x.Month_Actual),
                borderColor: 'white',
                borderWidth: 2,
            },
            {
                type: 'bar',
                label: 'Last Year',
                backgroundColor: 'rgba(0,102,255)',
                data: yearlyGraphLastYear(selectedPlantation).map(x => x.Last_MonthACT),
            },
        ],
    };

    const yearlyChartPolar = {
        labels: labelYear,
        datasets: [{
            label: 'My First Dataset',
            data: yearlyGraph(selectedPlantation).map(x => x.Month_Actual),
            backgroundColor: [
                // Color for polarchart year
                "#F386AA",
                "#DA92BF",
                "#A99CCB",
                "#7EAADA",
                "#5FB8E8",
                "#3DC3DC",
                "#52C0A5",
                "#69C07B",
                "#99B75C",
                "#D2A758",
                "#F79464",
                "#F58A7F",
                // End
                "#663366",
                "#9999CC",
                "#CCCCCC",
                "#669999",
                "#CCCC66",
                "#CC6600",
                "#9999FF",
                "#0066CC",
                "#99CCCC",
                "#999999",
                "#FFCC00",
                "#009999",
                "#99CC33",
                "#FF9900",
                "#999966",
                "#66CCCC",
                "#339966",
                "#CCCC33",
                "#003f5c",
                "#665191",
                "#a05195",
                "#d45087",
                "#2f4b7c",
                "#f95d6a",
                "#ff7c43",
                "#ffa600",
                "#EF6F6C",
                "#465775",
                "#56E39F",
                "#59C9A5",
                "#5B6C5D",
                "#0A2342",
                "#2CA58D",
                "#84BC9C",
                "#CBA328",
                "#F46197",
                "#DBCFB0",
                "#545775"
            ]
        }]
    };

    return (
        <div className='container-fluid content-container mt-5'>
            <h1 className='mt-3 mb-3'><b>Summary</b></h1>
            <h6 className="text-left mt-3">Filter By:  </h6>
            <div className='form-group d-flex'>
                <div>
                    <label className='label-summary' htmlFor='estate'>Type: </label>
                    <select className="form-input-summary" value={selectedType} onChange={handleSearchTypeChange}>
                        <option className='form-input-summary' value="Month">Month</option>
                        <option className='form-input-summary' value="Quarter">Quarter</option>
                        <option className='form-input-summary' value="Year">Year</option>
                    </select>
                </div>
                {
                    selectedType === 'Month' ? (
                        <div className='form-group d-flex mb-3'>
                            <div>
                                <label className='label-summary' htmlFor='estate'>Plantation: </label>
                                <select className="form-input-summary" value={selectedPlantation} onChange={handleSearchPlantationChange}>
                                    {
                                        searchPlantation.map((plant) => {
                                            return (
                                                <option className='form-input-summary' value={plant.Name}>{plant.Name}</option>
                                            )
                                        })
                                    }
                                    <option value="All">All</option>
                                </select>
                            </div>
                            <div className='d-flex'>
                                <label className='row justify-content-center align-self-center mx-2' htmlFor='month_year'>Month/Year: </label>
                                <DatePicker
                                    selected={searchDate}
                                    onChange={setSearchDate}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    showFullMonthYearPicker
                                    className="form-input-summary"
                                />
                            </div>
                        </div>
                    ) : selectedType === 'Quarter' ? (
                        <div className='form-group d-flex mb-3'>
                            <div>
                                <label className='label-summary' htmlFor='estate'>Plantation: </label>
                                <select className="form-input-summary" value={selectedPlantation} onChange={handleSearchPlantationChange}>
                                    {
                                        searchPlantation.map((plant) => {
                                            return (
                                                <option className='form-input-summary' value={plant.Name}>{plant.Name}</option>
                                            )
                                        })
                                    }
                                    <option value="All">All</option>
                                </select>
                            </div>
                            <div>
                                <label className='label-summary' htmlFor='quarter'>Quarter: </label>
                                <select className="form-input-summary" value={selectedQuarter} onChange={handleSearchQuarterChange}>
                                    <option className='form-input-summary' value="1Q">1Q</option>
                                    <option className='form-input-summary' value="2Q">2Q</option>
                                    <option className='form-input-summary' value="3Q">3Q</option>
                                    <option className='form-input-summary' value="4Q">4Q</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label className='label-summary' htmlFor='division'>Year:</label>
                                <select className="form-input-summary" value={selectedYear} onChange={handleSelectedYearChange}>
                                    {
                                        yearRange.map((x) => {
                                            return (
                                                <option value={x}>{x}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    ) : (
                        <div className='form-group d-flex mb-3'>
                            <div>
                                <label className='label-summary' htmlFor='estate'>Plantation: </label>
                                <select className="form-input-summary" value={selectedPlantation} onChange={handleSearchPlantationChange}>
                                    {
                                        searchPlantation.map((plant) => {
                                            return (
                                                <option className='form-input-summary' value={plant.Name}>{plant.Name}</option>
                                            )
                                        })
                                    }
                                    <option value="All">All</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label className='label-summary' htmlFor='year'>Year:</label>
                                <select className="form-input-summary" value={selectedYear} onChange={handleSelectedYearChange}>
                                    {
                                        yearRange.map((x) => {
                                            return (
                                                <option value={x}>{x}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    )
                }
            </div>
            {
                selectedType === 'Month' ? (

                    <div className='mt-5'>
                        <div>
                            <h4 className='mb-4'>Overall Progress {months[getMonth - 1]} ({selectedPlantation})</h4>
                            <Line
                                data={state1}
                                options={optionsLineChart}
                            />
                        </div>
                        <div className='container-fluid border border-3 rounded-5 p-5 my-auto mt-5'>
                            <h4 className='mb-4'>Daily Production</h4>
                            <Scrollbars style={{ width: "100%", height: 300 }}>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Day</th>
                                            <th>Today</th>
                                            <th>Todate</th>
                                            <th>Budget</th>
                                            <th>Variance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {
                                            dailyLogger(selectedPlantation).map((y) => {
                                                return (
                                                    <tr>
                                                        <td>{y.Date}</td>
                                                        <td>{y.Today_Actual.toFixed(2)}</td>
                                                        <td>{y.Todate_Actual.toFixed(2)}</td>
                                                        <td>{filterBarGraphTodateEstimate(selectedPlantation)}</td>
                                                        <td className={Math.sign(y.VarianceP) === -1 ? "text-danger" : "text-success"}>{y.VarianceP.toFixed(2)}</td>
                                                    </tr>
                                                )
                                            })
                                        } */}

                                        {
                                            filterGraphLineActualDailyDataTable(selectedPlantation).map((y) => {
                                                return (
                                                    <tr>
                                                        <td>{y.Date}</td>
                                                        <td>{y.Today_Actual.toFixed(2)}</td>
                                                        <td>{y.Todate_Actual.toFixed(2)}</td>
                                                        <td>{filterBarGraphTodateEstimate(selectedPlantation)}</td>
                                                        <td className={Math.sign(y.Todate_VarianceP) === -1 ? "text-danger" : "text-success"}>{y.Todate_VarianceP.toFixed(2)}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Scrollbars>
                        </div>
                        <div className='mt-5'>
                            <h4 className='mt-3'>Yield Summary</h4>
                            <div className='d-flex justify-content-start'>
                                <div className='container border border-3 rounded-5 p-3 my-auto'>
                                    <Scrollbars style={{ width: "100%", height: 200 }}>
                                        <Table borderless>
                                            <thead>
                                                <tr>
                                                    <th className='text-center'><h5>Division</h5></th>
                                                    <th className='text-center'><h5>Hectare</h5></th>
                                                    <th className='text-center'><h5>Mt</h5></th>
                                                    <th className='text-center'><h5>YPH</h5></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    yieldSummary(selectedPlantation).map((x) => {
                                                        return (
                                                            <tr>
                                                                {
                                                                    selectedPlantation === 'All' ? (
                                                                        <td className='text-center'><h5>{x.Division}({x.Plantation})</h5></td>
                                                                    ) : (
                                                                        <td className='text-center'><h5>{x.Division}</h5></td>
                                                                    )
                                                                }
                                                                <td className='text-center'><h4><b>{x.Hectare.toFixed(2)}</b></h4></td>
                                                                <td className='text-center'><h4><b>{x.Todate_Actual.toFixed(2)}</b></h4></td>
                                                                <td className='text-center'><h4 className={x.YieldPer_Hectare.toFixed(2) >= 1 ? 'text-success mx-3' : x.YieldPer_Hectare.toFixed(2) <= 1 && x.YieldPer_Hectare.toFixed(2) >= 0.5 ? 'text-warning mx-3' : x.YieldPer_Hectare <= 0.5 ? 'text-danger mx-3' : ''}><b>{x.YieldPer_Hectare.toFixed(2)}</b></h4></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                <tr>
                                                    <td className='text-center'><h5>Total</h5></td>
                                                    <td className='text-center'><h4><b>{yieldSummaryTotalYPH(selectedPlantation)[0].toFixed(2)}</b></h4></td>
                                                    <td className='text-center'><h4><b>{yieldSummaryTotalYPH(selectedPlantation)[1].toFixed(2)}</b></h4></td>
                                                    <td className='text-center'><h4 className={yieldSummaryTotalYPH(selectedPlantation)[2].toFixed(2) >= 1 ? 'text-success mx-3' : yieldSummaryTotalYPH(selectedPlantation)[2].toFixed(2) <= 1 && yieldSummaryTotalYPH(selectedPlantation)[2].toFixed(2) >= 0.5 ? 'text-warning mx-3' : yieldSummaryTotalYPH(selectedPlantation)[2].toFixed(2) <= 0.5 ? 'text-danger mx-3' : ''}><b>{yieldSummaryTotalYPH(selectedPlantation)[2].toFixed(2)}</b></h4></td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Scrollbars>
                                </div>
                                <div className='container rounded-5'>
                                    <Doughnut
                                        data={dataDoughnutChart}
                                        options={optionDoughnutChart}
                                        // plugins={pluginsTest}
                                        width={300}
                                        height={300}
                                    />
                                </div>
                            </div>
                            <div className='d-flex justify-content-between mb-5'>
                                <div className='container'>
                                    <h4 className='mb-2 mt-4'>Mill Summary</h4>
                                    <div className='mt-3'>
                                        <div className='container my-auto mx-auto border border-3 rounded-5 p-3'>
                                            <Scrollbars style={{ width: "100%", height: 200 }}>
                                                <Table borderless>
                                                    <thead>
                                                        <tr>
                                                            <td><h5>Mill</h5></td>
                                                            <td><h5>Mt</h5></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            millingSummary(selectedPlantation).map((x) => {
                                                                if (x.Mill === "NONE") {
                                                                    return null

                                                                } else {
                                                                    return (
                                                                        <tr>
                                                                            <td><h5>{x.Mill}</h5></td>
                                                                            <td><h4><b>{x.Total_Weight.toFixed(2)}</b></h4></td>
                                                                        </tr>
                                                                    )
                                                                }

                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Scrollbars>
                                        </div>
                                    </div>
                                </div>
                                <div className='container'>
                                    <h4 className='mb-2 mt-4'>Driver Summary</h4>
                                    <div className='mt-3 mb-5'>
                                        <div className='container my-auto mx-auto border border-3 rounded-5 p-3'>
                                            <Scrollbars style={{ width: "100%", height: 200 }}>
                                                <Table borderless>
                                                    <thead>
                                                        <tr>
                                                            <td><h5>Name</h5></td>
                                                            <td><h5>Total Mt</h5></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            driveSummary(selectedPlantation).map((x) => {
                                                                if (x.Driver === "NONE") {
                                                                    return null

                                                                } else {
                                                                    return (
                                                                        <tr>
                                                                            <td><h4><b>{empList.filter(data => data.Employee_id === x.Driver).map(x => x.Name)}</b></h4></td>
                                                                            <td><h4><b>{x.Total_Weight.toFixed(2)}</b></h4></td>
                                                                        </tr>
                                                                    )

                                                                }

                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Scrollbars>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : selectedType === 'Quarter' ? (
                    <div className='mt-5'>
                        <h4 className='mt-3 mb-5'>Overall Progress All Quarter ({selectedQuarter})</h4>
                        <div className='d-flex justify-content-around'>
                            <div className='container'>
                                <Chart
                                    data={quarterChart}
                                    options={optionsLineChart}
                                    height={300}
                                />
                            </div>
                            <div className='container'>
                                <PolarArea
                                    data={quarterChartPolar}
                                    options={optionPolarChart}
                                />
                            </div>
                        </div>
                        <div className='mt-5'>
                            <h4 className='mb-2'>Quarter Progress Detail</h4>
                            <div className='container d-flex'>
                                <div className='container-summary mt-5 mb-5 border border-3 rounded-5 p-3 mx-2'>
                                    <Table borderless>
                                        <tbody>
                                            <tr>
                                                <td><h5>Actual</h5></td>
                                                <td className='px-2'><h5>{quarterSummary(selectedPlantation).map(x => x.Quarter_Actual)}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h5>Budget</h5></td>
                                                <td className='px-2'><h5>{quarterSummary(selectedPlantation).map(x => x.Quarter_Estimate)}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h5>Variance Mt</h5></td>
                                                <td className='px-2'><h5 className={Math.sign(quarterSummary(selectedPlantation).map(x => x.Quarter_VarianceMT)) === -1 ? "text-danger" : "text-success"}>{quarterSummary(selectedPlantation).map(x => x.Quarter_VarianceMT)}</h5></td>
                                            </tr>
                                            <tr>
                                                <td><h5>Variance %</h5></td>
                                                <td className='px-2'><h5 className={Math.sign(quarterSummary(selectedPlantation).map(x => x.Quarter_VarianceP)) === -1 ? "text-danger" : "text-success"}>{quarterSummary(selectedPlantation).map(x => x.Quarter_VarianceP)} %</h5></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <div className='mt-5 mb-5 border border-3 rounded-5 p-3 mx-2'>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Month</th>
                                                <th>Actual</th>
                                                <th>Todate</th>
                                                <th>Budget Actual</th>
                                                <th>Budget Todate</th>
                                                <th>Monthly Variance %</th>
                                                <th>Todate Variance %</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                quarterLogSummary(selectedPlantation).map((y) => {
                                                    return (
                                                        <tr>
                                                            <td className='text-center'>{months[y.Month - 1]}</td>
                                                            <td className='text-center'>{y.Month_Actual}</td>
                                                            <td className='text-center'>{y.Todate_Actual}</td>
                                                            <td className='text-center'>{y.Month_Estimate}</td>
                                                            <td className='text-center'>{y.Todate_Estimate}</td>
                                                            <td className={Math.sign(y.Month_VarianceP) === -1 ? "text-danger text-center" : "text-success text-center"}>{y.Month_VarianceP}</td>
                                                            <td className={Math.sign(y.Todate_VarianceP) === -1 ? "text-danger text-center" : "text-success text-center"}>{y.Todate_VarianceP}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='mt-5'>
                        <h4 className='mt-3 mb-5'>Overall Annual Progress ({selectedYear})</h4>
                        <div className='container mt-3 mb-3'>
                            <Chart
                                data={yearlyChart}
                                options={optionsLineChart}
                            />
                        </div>
                        <div className='container-fluid mt-5 mb-5'>
                            <h4 className='mb-4'>Annual Progress Detail</h4>
                            <div className='container d-flex'>
                                <div className='container'>
                                    <PolarArea
                                        data={yearlyChartPolar}
                                        options={optionPolarChart}
                                        height={200}
                                        width={200}
                                    />
                                </div>
                                <div className='container'>
                                    <div className='container mt-5 mb-5 border border-3 rounded-5 p-3 mx-2'>
                                        <Table borderless>
                                            <tbody>
                                                <tr>
                                                    <td><h5>Actual</h5></td>
                                                    <td className='px-2'><h5>{yearlySummaryTable(selectedPlantation).map(x => x.Year_Actual)}</h5></td>
                                                </tr>
                                                <tr>
                                                    <td><h5>Budget</h5></td>
                                                    <td className='px-2'><h5>{yearlySummaryTable(selectedPlantation).map(x => x.Year_Estimate)}</h5></td>
                                                </tr>
                                                <tr>
                                                    <td><h5>Variance Mt</h5></td>
                                                    <td className='px-2'><h5 className={Math.sign(yearlySummaryTable(selectedPlantation).map(x => x.Year_VarianceMT)) === -1 ? "text-danger" : "text-success"}>{yearlySummaryTable(selectedPlantation).map(x => x.Year_VarianceMT)}</h5></td>
                                                </tr>
                                                <tr>
                                                    <td><h5>Variance %</h5></td>
                                                    <td className='px-2'><h5 className={Math.sign(yearlySummaryTable(selectedPlantation).map(x => x.Year_VarianceP)) === -1 ? "text-danger" : "text-success"}>{yearlySummaryTable(selectedPlantation).map(x => x.Year_VarianceP)} %</h5></td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                    <div className='container mt-5 mb-5 border border-3 rounded-5 p-3 mx-2'>
                                        <Scrollbars style={{ width: "100%", height: 250 }}>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Month</th>
                                                        <th>Actual</th>
                                                        <th>Budget Actual</th>
                                                        <th>Monthly Variance %</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        yearlyGraph(selectedPlantation).map((y) => {
                                                            return (
                                                                <tr>
                                                                    <td className='text-center'>{months[y.Month - 1]}</td>
                                                                    <td className='text-center'>{y.Month_Actual}</td>
                                                                    <td className='text-center'>{y.Month_Estimate}</td>
                                                                    <td className={Math.sign(y.Month_VarianceP) === -1 ? "text-danger text-center" : "text-success text-center"}>{y.Month_VarianceP}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        </Scrollbars>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>

    )
}

export default Summary