import {
    UPDATE_PASSWORD_FAIL,
    UPDATE_PASSWORD_SUCESS,
} from '../actions/types'

const initialState = {
    result: '',
}

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case UPDATE_PASSWORD_SUCESS:
            return {
                ...state,
                result: payload,
            }
        case UPDATE_PASSWORD_FAIL:
            return {
                ...state,
                result: payload,
            }
        default:
            return state
    }
}

