import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Line, Bar } from 'react-chartjs-2'
import axios from 'axios'
import { Chart, registerables } from 'chart.js';
import { BiSort } from 'react-icons/bi';
import { Table } from 'react-bootstrap'
import { format } from 'date-fns'
import { fil } from 'date-fns/locale';
import moment from 'moment'
Chart.register(...registerables);

function DashboardClerk({
    first_name_global,
    last_name_global,
    company_global,
    role_global,
    estate_global,
    employee_id_global
}) {

    const [getDatas, setGetDatas] = useState([])
    const [getDatasAllPlant, setGetDatasAllPlant] = useState([])
    const [yearlyYield, setYearlyYield] = useState([])
    const [filterEstate, setFilterEstate] = useState(estate_global)
    const [getPlantationData, setGetPlantationData] = useState([])
    const [getPlantationDataAll, setGetPlantationDataAll] = useState([])
    const [yieldPerHectare, setYieldPerHectare] = useState([])
    const [divs, setDivs] = useState([])
    const [estates, setEstate] = useState([])
    const [mills, setMills] = useState([])
    const [yearlyYieldAll, setYearlyYieldAll] = useState([])
    const [plotEstimate, setPlotEstimate] = useState([])
    const [plotEstimateAll, setPlotEstimateAll] = useState([])
    const [price, setPrice] = useState([])
    const [priceDay, setPriceDay] = useState([])

    const todayDate = new Date()
    const getCurrentMonth = todayDate.getMonth()
    const getCurrentMonthPlantation = todayDate.getMonth() + 1
    const getCurrentMonthPlantationPrevPrev = todayDate.getMonth() - 1
    const getCurrentYear = todayDate.getFullYear()
    const getCurrentYearPrev = todayDate.getFullYear() - 1

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBlistdiv/`)
            .then((response) => {
                setDivs(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBlistplant/`)
            .then((response) => {
                setEstate(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBlistmill/`)
            .then((response) => {
                setMills(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBreport/`)
            .then((response) => {
                setGetDatas(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBreportyear/`)
            .then((response) => {
                setYearlyYield(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBreportall/`)
            .then((response) => {
                setGetDatasAllPlant(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSByph/`)
            .then((response) => {
                setYieldPerHectare(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSByearyield/`)
            .then((response) => {
                setYearlyYieldAll(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBplantation/`)
            .then((response) => {
                setGetPlantationData(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBplantationall/`)
            .then((response) => {
                setGetPlantationDataAll(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBestplot/`)
            .then((response) => {
                setPlotEstimate(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBestplotall/`)
            .then((response) => {
                setPlotEstimateAll(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBffbrpricelog/`)
            .then((response) => {
                setPrice(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSffbpricelist/`)
            .then((response) => {
                setPriceDay(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    function handleFilterEstate(event) {
        setFilterEstate(event.target.value)
    }

    function testDate1(x) {
        const getDate = new Date(x.Date);
        const getMonth = getDate.getMonth()
        return getMonth
    }

    function testDate2(x) {
        const getDate = new Date(x.Date);
        const getYear = getDate.getFullYear()
        return getYear
    }

    const xDailyDate = moment(new Date()).subtract(1, 'day')
    const dailyDateYesterday = format(new Date(xDailyDate), 'yyyy-MM-dd')
    const dailyDate = format(new Date(), 'yyyy-MM-dd')

    const test1 = moment(new Date()).subtract(1, 'day')
    const test2 = new Date(test1)
    const dailyYieldDateFormate = format(new Date(test2), 'yyyy-MM-dd')

    var months = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];

    const prevYear = getPlantationData.filter(data => data.Month >= getCurrentMonthPlantationPrevPrev && data.Month <= getCurrentMonthPlantation && data.Year === getCurrentYearPrev && data.Plantation.includes(filterEstate)).sort((a, b) => a.Month - b.Month)
    const currYear = getPlantationData.filter(data => data.Month >= getCurrentMonthPlantationPrevPrev && data.Month <= getCurrentMonthPlantation && data.Year === getCurrentYear && data.Plantation.includes(filterEstate)).sort((a, b) => a.Month - b.Month)
    const hectareYield = yieldPerHectare.filter(data => data.Month === getCurrentMonthPlantation && data.Year === getCurrentYear)
    const prevYearAll = getPlantationDataAll.filter(data => data.Month >= getCurrentMonthPlantationPrevPrev && data.Month <= getCurrentMonthPlantation && data.Year === getCurrentYearPrev).sort((a, b) => a.Month - b.Month)
    const currYearAll = getPlantationDataAll.filter(data => data.Month >= getCurrentMonthPlantationPrevPrev && data.Month <= getCurrentMonthPlantation && data.Year === getCurrentYear).sort((a, b) => a.Month - b.Month)
    const prevYearEst = plotEstimate.filter(data => data.Month >= getCurrentMonthPlantationPrevPrev && data.Month <= getCurrentMonthPlantation && data.Year === getCurrentYearPrev && data.Plantation_Name_id.includes(filterEstate)).sort((a, b) => a.Month - b.Month)
    const currYearEst = plotEstimate.filter(data => data.Month >= getCurrentMonthPlantationPrevPrev && data.Month <= getCurrentMonthPlantation && data.Year === getCurrentYear && data.Plantation_Name_id.includes(filterEstate)).sort((a, b) => a.Month - b.Month)
    const prevYearAllEst = plotEstimateAll.filter(data => data.Month >= getCurrentMonthPlantationPrevPrev && data.Month <= getCurrentMonthPlantation && data.Year === getCurrentYearPrev).sort((a, b) => a.Month - b.Month)
    const currYearAllEst = plotEstimateAll.filter(data => data.Month >= getCurrentMonthPlantationPrevPrev && data.Month <= getCurrentMonthPlantation && data.Year === getCurrentYear).sort((a, b) => a.Month - b.Month)

    function filterGraphLineActualData(filterEstate) {
        if (filterEstate === 'All') {
            const filter = getDatasAllPlant.filter(data => testDate1(data) === getCurrentMonth && testDate2(data) === getCurrentYear).sort((a, b) => {
                return new Date(a.Date) -
                    new Date(b.Date)
            }).map(x => x.Todate_Actual)
            return filter
        } else {
            const filter = getDatas.filter(data => testDate1(data) === getCurrentMonth && testDate2(data) === getCurrentYear && data.Plantation.includes(filterEstate)).sort((a, b) => {
                return new Date(a.Date) -
                    new Date(b.Date)
            }).map(x => x.Todate_Actual)
            return filter
        }
    }

    function filterGraphLineActualDailyData(filterEstate) {
        if (filterEstate === 'All') {
            const filter = getDatasAllPlant.filter(data => testDate1(data) === getCurrentMonth && testDate2(data) === getCurrentYear).sort((a, b) => {
                return new Date(a.Date) -
                    new Date(b.Date)
            }).map(x => x.Today_Actual)
            return filter
        } else {
            const filter = getDatas.filter(data => testDate1(data) === getCurrentMonth && testDate2(data) === getCurrentYear && data.Plantation.includes(filterEstate)).sort((a, b) => a.Date - b.Date).sort((a, b) => {
                return new Date(a.Date) -
                    new Date(b.Date)
            }).map(x => x.Today_Actual)
            return filter
        }
    }

    function filterGraphLineActualLabel(filterEstate) {
        if (filterEstate === 'All') {
            const filter = getDatasAllPlant.filter(data => testDate1(data) === getCurrentMonth && testDate2(data) === getCurrentYear).sort((a, b) => {
                return new Date(a.Date) -
                    new Date(b.Date)
            }).map(x => moment(x.Date, 'YYYY/MM/DD').format('D'))
            return filter
        } else {
            const filter = getDatas.filter(data => testDate1(data) === getCurrentMonth && testDate2(data) === getCurrentYear && data.Plantation.includes(filterEstate)).sort((a, b) => {
                return new Date(a.Date) -
                    new Date(b.Date)
            }).map(x => moment(x.Date, 'YYYY/MM/DD').format('D'))
            return filter
        }
    }

    function filterGraphLineActualEstimate(filterEstate) {
        if (filterEstate === 'All') {
            const filter = getDatasAllPlant.filter(data => testDate1(data) === getCurrentMonth && testDate2(data) === getCurrentYear).sort((a, b) => {
                return new Date(a.Date) - new Date(b.Date)
            }).map(x => x.Todate_Estimate)
            return filter
        } else {
            const filter = getDatas.filter(data => testDate1(data) === getCurrentMonth && testDate2(data) === getCurrentYear && data.Plantation.includes(filterEstate)).sort((a, b) => {
                return new Date(a.Date) - new Date(b.Date)
            }).map(x => x.Todate_Estimate)
            return filter
        }
    }

    function filterDailyMonthlyYield(filterEstate) {
        if (filterEstate === 'All') {
            const filter = getDatasAllPlant.filter(data => data.Date === dailyYieldDateFormate)
            return filter
        } else {
            const filter = getDatas.filter(data => data.Date === dailyYieldDateFormate && data.Plantation.includes(filterEstate))
            return filter
        }
    }

    function filterYearYieldAll(filterEstate) {
        if (filterEstate === 'All') {
            const filter = yearlyYieldAll.filter(data => data.Year === getCurrentYear)
            return filter
        } else {
            const filter = yearlyYield.filter(data => data.Year === getCurrentYear && data.Plantation.includes(filterEstate))
            return filter
        }
    }

    function filterBarGraphTodateActualCurrentYearAll(filterEstate) {
        if (filterEstate === 'All') {
            const filter = currYearAll
            return filter
        } else {
            const filter = currYear
            return filter
        }
    }

    function filterBarGraphTodateActualCurrentYearAllEst(filterEstate) {
        if (filterEstate === 'All') {
            const filter = currYearAllEst
            return filter
        } else {
            const filter = currYearEst
            return filter
        }
    }

    function filterBarGraphTodateActualPreviousYearAll(filterEstate) {
        if (filterEstate === 'All') {
            const filter = prevYearAll
            return filter
        } else {
            const filter = prevYear
            return filter
        }
    }

    function filterBarGraphTodateActualPreviousYearAllEst(filterEstate) {
        if (filterEstate === 'All') {
            const filter = prevYearAllEst
            return filter
        } else {
            const filter = prevYearEst
            return filter
        }
    }

    const test = new Date()
    const month = test.getMonth() + 1
    const year = test.getFullYear()
    const test3 = new Date(year, month, 0).getDate();

    function filterBarGraphTodateEstimate(filterEstate) {
        if (filterEstate === 'All') {
            const estCurrent = plotEstimateAll.filter(data => data.Month === month && data.Year === year).map(x => x.Today_Estimate)
            return estCurrent
        } else {
            const estCurrent = plotEstimate.filter(data => data.Month === month && data.Year === year && data.Plantation_Name_id.includes(filterEstate)).map(x => x.Today_Estimate)
            return estCurrent
        }
    }

    let array = []
    let arrayDay = []

    for (let i = 1; i <= test3; i++) {
        const t = i * filterBarGraphTodateEstimate(filterEstate)
        array.push(t)
        arrayDay.push(i)
    }

    const priceFiltered = price.filter(x => x.Year === year).sort((a, b) => a.Mill.localeCompare(b.Mill))

    function priceDayFilter(dailyDate) {
        const priceDayFiltered = priceDay.filter(x => x.Date === dailyDate).sort((a, b) => a.Mill.localeCompare(b.Mill))
        if (!priceDayFiltered) {
            return null
        } else {
            return priceDayFiltered
        }
    }

    function priceDayFilterYesterday(dailyDateYesterday) {
        const priceDayFiltered = priceDay.filter(x => x.Date === dailyDateYesterday).sort((a, b) => a.Mill.localeCompare(b.Mill))
        if (!priceDayFiltered) {
            return null
        } else {
            return priceDayFiltered
        }
    }

    const state1 = {
        // labels: getDatas.filter(data => testDate1(data) === getCurrentMonth && testDate2(data) === getCurrentYear && data.Plantation.includes(filterEstate)).map(x => x.Date),
        labels: arrayDay,
        datasets: [
            {
                label: 'Budget FFB Production',
                backgroundColor: 'rgba(67,196,240)',
                lineTension: 0.1,
                borderColor: 'rgba(67,196,240)',
                order: 3,
                // data: getDatas.filter(data => testDate1(data) === getCurrentMonth && testDate2(data) === getCurrentYear && data.Plantation.includes(filterEstate)).map(x => x.Todate_Estimate),
                // data: filterGraphLineActualEstimate(filterEstate)
                data: array
            },
            {
                label: 'Daily FFB Production',
                backgroundColor: 'rgba(255,166,0)',
                lineTension: 0.1,
                borderColor: 'rgba(255,166,0)',
                order: 1,
                // data: getDatas.filter(data => testDate1(data) === getCurrentMonth && testDate2(data) === getCurrentYear && data.Plantation.includes(filterEstate)).sort((a,b) => a.Date - b.Date).map(x => x.Todate_Actual),
                data: filterGraphLineActualDailyData(filterEstate)

            },
            {
                label: 'Cumulative FFB Production',
                backgroundColor: 'rgba(240,111,237)',
                lineTension: 0.1,
                borderColor: 'rgba(240,111,237)',
                order: 2,
                // data: getDatas.filter(data => testDate1(data) === getCurrentMonth && testDate2(data) === getCurrentYear && data.Plantation.includes(filterEstate)).sort((a,b) => a.Date - b.Date).map(x => x.Todate_Actual),
                data: filterGraphLineActualData(filterEstate)

            },
        ],
    }

    const state2 = {
        labels: filterBarGraphTodateActualCurrentYearAllEst(filterEstate).map(x => months[x.Month - 1]),
        datasets: [
            {
                label: 'Budget',
                backgroundColor: 'rgba(255,0,0)',
                data: filterBarGraphTodateActualCurrentYearAllEst(filterEstate).map(x => x.Today_Estimate * new Date(x.Year, x.Month, 0).getDate()),
            },
            {
                label: 'Current Year',
                backgroundColor: 'rgba(255,168,0)',
                data: filterBarGraphTodateActualCurrentYearAll(filterEstate).map(x => x.Todate_Actual),
            },
            {
                label: 'Last Year',
                backgroundColor: 'rgba(0,102,255)',
                data: filterBarGraphTodateActualPreviousYearAll(filterEstate).map(x => x.Todate_Actual),
            },
        ]
    }

    const options = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: true,
        },
        scales: {
            y: {
                title: {
                    display: true,
                    text: 'Metric Tonne (Mt)',
                    font: {
                        size: 20,
                    }
                }
            },
            x: {
                title: {
                    display: true,
                    text: 'Day',
                    font: {
                        size: 20,
                    }
                }
            }
        },
        plugins: {
            tooltip: {
                backgroundColor: 'white',
                borderColor: 'rgb(128,128,128)',
                borderWidth: 2,
                titleColor: 'black',
                titleAlign: 'center',
                displayColor: true,
                boxWidth: 0,
                boxHeight: 0,
                bodyAlign: 'center',
                usePointStyle: true,
                bodyFontFamily: 'Arial',
                bodyFontSize: 50,
                bodySpacing: 2,
                padding: 30,
                callbacks: {
                    title: function (item, everything) {
                        return
                    },
                    labelTextColor: function (tooltipItem, chart) {
                        return state1.datasets.borderColor;
                    },
                    label: function (item, everything) {

                        let label = item.dataset.label
                        return label
                    },
                    afterLabel: function (item, everything) {
                        let value = item.formattedValue
                        return value + " Mt"
                    },
                },
                bodyFont: {
                    size: 15,
                    padding: 15,
                },
            }
        }
    };

    const b = filterDailyMonthlyYield(filterEstate).map(x => x.Today_VarianceP)

    return (
        <div className='container-fluid mt-5 content-container'>
            <h1 className='mt-3 mb-3'><b>Dashboard {estate_global}</b></h1>
            <div className="container-fluid chart">
                {/* <form>
                    <div className='form-group d-flex justify-content-between mb-3'>
                        <h5><b>{filterEstate}</b></h5>
                        <div>
                            <label htmlFor="estate-filter">Estate:</label>
                            <select className="form-input-summary" value={filterEstate} onChange={handleFilterEstate}>
                                {
                                    estates.map((x) => {
                                        return (
                                            <option value={x.Name}>{x.Name}</option>
                                        )
                                    })
                                }
                                <option value="All">All</option>
                            </select>
                        </div>
                    </div>
                </form> */}
                <h4 className='mb-4'>Overall Progress (Current Month)</h4>
                <div className='d-flex'>
                    <Line
                        data={state1}
                        options={options}
                    />
                </div>
            </div>
            <div className='container-fluid mt-5 mb-5 d-flex justify-content-around'>
                <div className='container'>
                    <h4 className='mb-4'>Last 3 Month Harvesting Volume</h4>
                    <div className='border border-3 rounded-3 p-3'>
                        <Bar
                            data={state2}
                            options={
                                {
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    plugins: {
                                        legend: {
                                            position: 'top'
                                        },
                                    },

                                }}
                            height={500}
                        />
                    </div>
                </div>
                <div className="container justify-content-center mx-auto">
                    <h4 className='mb-4 mx-2'>Other Information</h4>
                    <div className='container'>
                        <div className='container d-flex'>
                            {
                                b.length !== 0 ?
                                    filterDailyMonthlyYield(filterEstate).map((x) => {
                                        if (Math.sign(x.Todate_VarianceP) === -1) {
                                            return (
                                                <div className='container justify-content-center mx-2 border border-3 rounded-3 p-1 mb-4'>
                                                    <h5 className='text-center'>Daily FFB Production</h5>
                                                    <div className='container d-flex justify-content-between'>
                                                        <div className='justify-content-center mx-auto my-auto'>
                                                            <h6 className='text-muted small'>Variance (%)</h6>
                                                            <h5 className='justify-content-center text-danger'>{Math.abs(x.Today_VarianceP).toFixed(2)}%</h5>
                                                        </div>
                                                        <div className='justify-content-center mx-auto my-auto'>
                                                            <span className='text-muted small'>Actual Daily</span>
                                                            <h6>{x.Today_Actual} Mt</h6>
                                                            <span className='text-muted small'>Estimated Daily</span>
                                                            <h6>{x.Today_Estimate} Mt</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div className='container justify-content-center mx-2 border border-3 rounded-3 p-1 mb-4'>
                                                    <h5 className='text-center'>Daily FFB Production</h5>
                                                    <div className='container d-flex justify-content-between'>
                                                        <div className='justify-content-center mx-auto my-auto'>
                                                            <h6 className='text-muted small'>Variance (%)</h6>
                                                            <h5 className='justify-content-center text-success'>{x.Today_VarianceP.toFixed(2)}%</h5>
                                                        </div>
                                                        <div className='justify-content-center mx-auto my-auto'>
                                                            <span className='text-muted small'>Actual Daily</span>
                                                            <h6>{x.Today_Actual} Mt</h6>
                                                            <span className='text-muted small'>Estimated Daily</span>
                                                            <h6>{x.Today_Estimate} Mt</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }

                                    }) : (
                                        <div className='container justify-content-center mx-2 border border-3 rounded-5 p-1 mb-4'>
                                            <h5 className='text-center'>Daily FFB Production</h5>
                                            <div className='container d-flex justify-content-between'>
                                                <div className='justify-content-center mx-auto my-auto'>
                                                    <h6 className='text-muted small'>Variance (%)</h6>
                                                    <h5 className='justify-content-center text-danger'>0 %</h5>
                                                </div>
                                                <div className='justify-content-center mx-auto my-auto'>
                                                    <span className='text-muted small'>Actual Daily</span>
                                                    <h6>0 Mt</h6>
                                                    <span className='text-muted small'>Estimated Daily</span>
                                                    <h6>0 Mt</h6>
                                                </div>
                                            </div>
                                        </div>
                                    )
                            }
                            {
                                b.length !== 0 ?
                                    filterDailyMonthlyYield(filterEstate).map((x) => {
                                        if (Math.sign(x.Todate_VarianceP) === -1) {
                                            return (
                                                <div className='container justify-content-center mx-2 border border-3 rounded-5 p-1 mb-4'>
                                                    <h5 className='text-center'>Monthly FFB Production</h5>
                                                    <div className='container d-flex justify-content-between'>
                                                        <div className='justify-content-center mx-auto my-auto'>
                                                            <h6 className='text-muted small'>Variance (%)</h6>
                                                            <h5 className='justify-content-center text-danger'>{Math.abs(x.Todate_VarianceP)}%</h5>
                                                        </div>
                                                        <div className='justify-content-center mx-auto my-auto'>
                                                            <span className='text-muted small'>Actual Monthly</span>
                                                            <h6>{x.Todate_Actual} Mt</h6>
                                                            <span className='text-muted small'>Estimated Monthly</span>
                                                            <h6>{x.Todate_Estimate} Mt</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div className='container justify-content-center mx-2 border border-3 rounded-3 p-1 mb-4'>
                                                    <h5 className='text-center'>Monthly FFB Production</h5>
                                                    <div className='container d-flex justify-content-between'>
                                                        <div className='justify-content-center mx-auto my-auto'>
                                                            <h6 className='text-muted small'>Variance (%)</h6>
                                                            <h5 className='justify-content-center text-success'>{x.Todate_VarianceP}%</h5>
                                                        </div>
                                                        <div className='justify-content-center mx-auto my-auto'>
                                                            <span className='text-muted small'>Actual Monthly</span>
                                                            <h6>{x.Todate_Actual} Mt</h6>
                                                            <span className='text-muted small'>Estimated Monthly</span>
                                                            <h6>{x.Todate_Estimate} Mt</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }

                                    }) : (
                                        <div className='container justify-content-center mx-2 border border-3 rounded-3 p-1 mb-4'>
                                            <h5 className='text-center'>Monthly FFB Production</h5>
                                            <div className='container d-flex justify-content-between'>
                                                <div className='justify-content-center mx-auto my-auto'>
                                                    <h6 className='text-muted small'>Variance (%)</h6>
                                                    <h5 className='justify-content-center text-danger'>0 %</h5>
                                                </div>
                                                <div className='justify-content-center mx-auto my-auto'>
                                                    <span className='text-muted small'>Actual Monthly</span>
                                                    <h6>0 Mt</h6>
                                                    <span className='text-muted small'>Estimated Monthly</span>
                                                    <h6>0 Mt</h6>
                                                </div>
                                            </div>
                                        </div>
                                    )
                            }
                        </div>
                        <div className='container d-flex'>
                            {
                                filterYearYieldAll(filterEstate).map((x) => {
                                    if (Math.sign(x.Todate_VarianceP) === -1) {
                                        return (
                                            <div className='container justify-content-center mx-2 border border-3 rounded-5 p-1 mb-4'>
                                                <h5 className='text-center'>Annual FFB Production</h5>
                                                <div className='container d-flex justify-content-between'>
                                                    <div className='justify-content-center mx-auto my-auto'>
                                                        <small className='text-muted small'>Variance (%)</small>
                                                        <h5 className='justify-content-center text-danger'>{Math.abs(x.Todate_VarianceP).toFixed(2)}%</h5>
                                                    </div>
                                                    <div className='justify-content-center mx-auto my-auto'>
                                                        <span className='text-muted small'>Actual Yearly</span>
                                                        <h6>{x.Todate_Actual.toFixed(2)} Mt</h6>
                                                        <span className='text-muted small'>Estimated Yearly</span>
                                                        <h6>{x.Todate_Estimate.toFixed(2)} Mt</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className='container justify-content-center mx-2 border border-3 rounded-3 p-1 mb-4'>
                                                <h5 className='text-center'>Annual FFB Production</h5>
                                                <div className='container d-flex justify-content-between'>
                                                    <div className='justify-content-center mx-auto my-auto'>
                                                        <h6 className='text-muted small'>Variance (%)</h6>
                                                        <h5 className='justify-content-center text-success'>{x.Todate_VarianceP.toFixed(2)}%</h5>
                                                    </div>
                                                    <div className='justify-content-center mx-auto my-auto'>
                                                        <span className='text-muted small'>Actual Yearly</span>
                                                        <h6>{x.Todate_Actual.toFixed(2)} Mt</h6>
                                                        <span className='text-muted small'>Estimated Yearly</span>
                                                        <h6>{x.Todate_Estimate.toFixed(2)} Mt</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                })
                            }


                            <div className='container justify-content-center mx-2 p-2 mb-4 border border-3 yield'>
                                <h5 className='text-center pb-2'>Yield Per Hectare (To-date)</h5>
                                <div className='container d-flex'>
                                    <div>
                                        {
                                            hectareYield.map((x) => {
                                                return (
                                                    <div className='d-flex justify-content-around'>
                                                        <div>
                                                            <h6 className='text-center mx-3'>{x.Plantation}</h6>
                                                        </div>
                                                        <div>
                                                            <h6 className={x.YieldPer_Hectare.toFixed(2) > 1 ? 'text-center text-success mx-3' : x.YieldPer_Hectare.toFixed(2) < 1 && x.YieldPer_Hectare.toFixed(2) > 0.5 ? 'text-center text-warning mx-3' : x.YieldPer_Hectare < 0.5 ? 'text-center text-danger mx-3' : ''}>{x.YieldPer_Hectare.toFixed(2)}</h6>
                                                        </div>
                                                        {/* <h6 className='text-center'>{x.YieldPer_Hectare.toFixed(2)}</h6> */}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='container'>
                            <div className='container justify-content-center mx-2 p-2 mb-4 border border-3'>
                                <h5 className='text-center pb-2'>Price</h5>
                                <Table borderless>
                                    <thead>
                                        <tr>
                                            <th>Mill</th>
                                            <th>Today</th>
                                            <th>Yesterday</th>
                                            <th>Average</th>
                                            <th>Min</th>
                                            <th>Max</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            priceFiltered.map((x) => {
                                                if (priceDayFilter(dailyDate).map(x => x.OER).length === 0 && priceDayFilterYesterday(dailyDateYesterday).length === 0) {
                                                    return (
                                                        <tr>
                                                            <td>{x.Mill}</td>
                                                            <td>Pending</td>
                                                            <td>Pending</td>
                                                            <td>{x.AVG}</td>
                                                            <td>{x.MIN}</td>
                                                            <td>{x.MAX}</td>
                                                        </tr>
                                                    )
                                                } else if (priceDayFilter(dailyDate).map(x => x.OER).length === 0 && priceDayFilterYesterday(dailyDateYesterday).map(x => x.OER).length !== 0 && priceDayFilterYesterday(dailyDateYesterday).map(x => x.Price)[0] !== 0) {
                                                    return (
                                                        <tr>
                                                            <td>{x.Mill}</td>
                                                            <td>Pending</td>
                                                            <td>{priceDayFilterYesterday(dailyDateYesterday).filter(y => y.Mill === x.Mill).map(x => x.Price)[0]}</td>
                                                            <td>{x.AVG}</td>
                                                            <td>{x.MIN}</td>
                                                            <td>{x.MAX}</td>
                                                        </tr>
                                                    )
                                                } else if (priceDayFilter(dailyDate).map(x => x.OER).length === 0 && priceDayFilterYesterday(dailyDateYesterday).map(x => x.OER).length !== 0 && priceDayFilterYesterday(dailyDateYesterday).map(x => x.Price)[0] === 0) {
                                                    return (
                                                        <tr>
                                                            <td>{x.Mill}</td>
                                                            <td>Pending</td>
                                                            <td>{priceDayFilterYesterday(dailyDateYesterday).filter(y => y.Mill === x.Mill).map(x => x.OER)[0]}</td>
                                                            <td>{x.AVG}</td>
                                                            <td>{x.MIN}</td>
                                                            <td>{x.MAX}</td>
                                                        </tr>
                                                    )
                                                } else if (priceDayFilter(dailyDate).map(x => x.OER).length !== 0 && priceDayFilterYesterday(dailyDateYesterday).map(x => x.OER).length === 0 && priceDayFilter(dailyDate).map(x => x.Price)[0] !== 0) {
                                                    return (
                                                        <tr>
                                                            <td>{x.Mill}</td>
                                                            <td>{priceDayFilter(dailyDate).filter(y => y.Mill === x.Mill).map(x => x.Price)[0]}</td>
                                                            <td>Pending</td>
                                                            <td>{x.AVG}</td>
                                                            <td>{x.MIN}</td>
                                                            <td>{x.MAX}</td>
                                                        </tr>
                                                    )
                                                } else if (priceDayFilter(dailyDate).map(x => x.OER).length !== 0 && priceDayFilterYesterday(dailyDateYesterday).map(x => x.OER).length === 0 && priceDayFilter(dailyDate).map(x => x.Price)[0] === 0) {
                                                    return (
                                                        <tr>
                                                            <td>{x.Mill}</td>
                                                            <td>{priceDayFilter(dailyDate).filter(y => y.Mill === x.Mill).map(x => x.OER)[0]}</td>
                                                            <td>Pending</td>
                                                            <td>{x.AVG}</td>
                                                            <td>{x.MIN}</td>
                                                            <td>{x.MAX}</td>
                                                        </tr>
                                                    )
                                                } else if (priceDayFilter(dailyDate).map(x => x.Price)[0] === 0 && priceDayFilterYesterday(dailyDateYesterday).map(x => x.Price)[0] === 0) {
                                                    return (
                                                        <tr>
                                                            <td>{x.Mill}</td>
                                                            <td>{priceDayFilter(dailyDate).filter(y => y.Mill === x.Mill).map(x => x.OER)[0]}</td>
                                                            <td>{priceDayFilterYesterday(dailyDateYesterday).filter(y => y.Mill === x.Mill).map(x => x.OER)[0]}</td>
                                                            <td>{x.AVG}</td>
                                                            <td>{x.MIN}</td>
                                                            <td>{x.MAX}</td>
                                                        </tr>
                                                    )
                                                } else if (priceDayFilter(dailyDate).map(x => x.Price)[0] === 0 && priceDayFilterYesterday(dailyDateYesterday).map(x => x.Price)[0] !== 0) {
                                                    return (
                                                        <tr>
                                                            <td>{x.Mill}</td>
                                                            <td>{priceDayFilter(dailyDate).filter(y => y.Mill === x.Mill).map(x => x.OER)[0]}</td>
                                                            <td>{priceDayFilterYesterday(dailyDateYesterday).filter(y => y.Mill === x.Mill).map(x => x.Price)[0]}</td>
                                                            <td>{x.AVG}</td>
                                                            <td>{x.MIN}</td>
                                                            <td>{x.MAX}</td>
                                                        </tr>
                                                    )
                                                } else if (priceDayFilter(dailyDate).map(x => x.Price)[0] !== 0 && priceDayFilterYesterday(dailyDateYesterday).map(x => x.Price)[0] === 0) {
                                                    return (
                                                        <tr>
                                                            <td>{x.Mill}</td>
                                                            <td>{priceDayFilter(dailyDate).filter(y => y.Mill === x.Mill).map(x => x.Price)[0]}</td>
                                                            <td>{priceDayFilterYesterday(dailyDateYesterday).filter(y => y.Mill === x.Mill).map(x => x.OER)[0]}</td>
                                                            <td>{x.AVG}</td>
                                                            <td>{x.MIN}</td>
                                                            <td>{x.MAX}</td>
                                                        </tr>
                                                    )
                                                } else {
                                                    return (
                                                        <tr>
                                                            <td>{x.Mill}</td>
                                                            <td>{priceDayFilter(dailyDate).filter(y => y.Mill === x.Mill).map(x => x.Price)[0]}</td>
                                                            <td>{priceDayFilterYesterday(dailyDateYesterday).filter(y => y.Mill === x.Mill).map(x => x.Price)[0]}</td>
                                                            <td>{x.AVG}</td>
                                                            <td>{x.MIN}</td>
                                                            <td>{x.MAX}</td>
                                                        </tr>
                                                    )
                                                }

                                            })
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    first_name_global: state.profile.first_name,
    last_name_global: state.profile.last_name,
    company_global: state.profile.company,
    role_global: state.profile.role,
    estate_global: state.profile.estate,
    employee_id_global: state.profile.employee_id
  })

  export default connect(mapStateToProps, {})(DashboardClerk)