import React, { useState } from 'react'
import { Navigate, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { login } from '../actions/auth'
import CSRFToken from '../components/CSRFToken'
import pomaslogo from './POMAS-LOGO.png'
import { ToastContainer } from 'react-toastify'
import '../../node_modules/react-toastify/dist/ReactToastify.css'

function Login({ login, isAuthenticated, isRole }) {

  const [formData, setFormData] = useState({
    user_name: '',
    password: '',
  })


  const { user_name, password } = formData

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

  const onSubmit = e => {
    e.preventDefault();
    login(user_name, password);
    
  }

  if (isAuthenticated && (isRole === "Clerk" || isRole === 'Senior Asisstant Manager'))
    return <Navigate to="/dashboard" />
  else if (isAuthenticated && (isRole === "Assistant Manager" || isRole === "Account"))
    return <Navigate to="/dashboard_am" />
  else if (isAuthenticated && (isRole === "Manager" || isRole === 'Executive Charmain' || isRole === 'General Manager'))
    return <Navigate to="/dashboard_m" />


  return (
    <div className='container mt-5'>
       <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <img src={pomaslogo} width="30%" className='center-logo' />
      <h4> </h4>
      <h2>Sign into your account</h2>
      <form onSubmit={e => onSubmit(e)}>
        <CSRFToken />
        <div className='form-group'>
          <label className='form-label mt-3'>Username:</label>
          <input className='form-control' type='text' placeholder='Username*' name='user_name' onChange={e => onChange(e)} value={user_name} required />
        </div>
        <div className='form-group'>
          <label className='form-label mt-3'>Password:</label>
          <input className='form-control' type='password' placeholder='Password*' name='password' onChange={e => onChange(e)} value={password} minLength='6' required />
        </div>
        <button className='btn btn-primary mt-3' type='submit'>Login</button>
      </form>

    </div>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isRole: state.profile.role
})

export default connect(mapStateToProps, { login })(Login)