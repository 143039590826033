import React, { Fragment, Component, useState } from 'react';
import { Link, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../actions/auth'
import * as AiIcons from "react-icons/ai";
import { FaChevronRight } from 'react-icons/fa';
import { FaChartPie } from "react-icons/fa";
import { MdOutlineInput } from "react-icons/md"
import { MdManageAccounts } from "react-icons/md"
import { RiBarChartBoxFill } from "react-icons/ri"
import { IoPeopleSharp, IoLogOut, IoDocumentText } from "react-icons/io5"
import { BsClipboardData } from "react-icons/bs";
import pomaslogo from '../containers/POMAS-LOGO.png'


function Navbar({ isAuthenticated, isRole, logout }) {
  const [show, setShow] = useState(false)

  function toggle() {
    if (show === false) {
      setShow(true)
    } else if (show === true) {
      setShow(false)
    }
  }

  const authLinks = (

    <div id='sidebar'>
      <div className='sidebar-header'>
        <img src={pomaslogo} width="70%" className='center-logo'/>
        {/* <h3>POMAS</h3> */}
      </div>
      <NavLink className="nav-link" to="/dashboard">
        <FaChartPie className='logo-resp'/><span className='menu-text'>Dashboard</span>
      </NavLink>
      <NavLink className="nav-link" to="/summary">
        <RiBarChartBoxFill className='logo-resp' /><span className='menu-text'>Summary</span>
      </NavLink>
      <NavLink className="nav-link" to="/input_data">
        <MdOutlineInput className='logo-resp'/><span className='menu-text'>Input Data</span>
      </NavLink>
      {/* <NavLink className="nav-link" to="/report">
        <IoDocumentText className='logo-resp'/><span className='menu-text'>Report</span>
      </NavLink> */}
      {/* <NavLink className="nav-link" to="/mc_dashboard">
        <IoDocumentText className='logo-resp'/><span className='menu-text'>Machinery</span>
      </NavLink> */}
      
      <div className="nav-link">
        <MdManageAccounts className='logo-resp'/><span className="nav-link-drop menu-text" onClick={() => toggle()}>Management   <FaChevronRight className='logo-resp'/></span>
      </div>
      <div className={show === true ? "submenu display" : "submenu"}>
        {/* <NavLink className="nav-link" to="/emp">
          <IoPeopleSharp className='icondrop logo-resp'/><span className='menu-text submenudrop'>Employee Data</span>
        </NavLink>
        <NavLink className="nav-link" to="/est">
          <BsClipboardData className='icondrop logo-resp'/><span className='menu-text submenudrop'>Estimate Data</span>
        </NavLink> */}
        <NavLink className="nav-link" to="/profile">
          <AiIcons.AiFillProfile className='icondrop'/><span className='menu-text submenudrop'>Profile</span>
        </NavLink>
      </div>
      <div className='divider-navbar'></div>
      {/* <div className="nav-link logout">
        <IoLogOut /><a className="nav-link-drop menu-text" onClick={logout} href='#!'>Logout</a>
      </div> */}
      <NavLink className="logout nav-link" onClick={logout} to="#!">
        <IoLogOut /><span className='menu-text'>Logout</span>
      </NavLink>

    </div>
  )

  const authLinksAM = (

    <div id='sidebar'>
      <div className='sidebar-header'>
        <img src={pomaslogo} width="70%" className='center-logo'/>
        {/* <h3>POMAS</h3> */}
      </div>
      <NavLink className="nav-link" to="/dashboard_am">
        <FaChartPie className='logo-resp'/><span className='menu-text'>Dashboard</span>
      </NavLink>
      <NavLink className="nav-link" to="/summary_am">
        <RiBarChartBoxFill className='logo-resp' /><span className='menu-text'>Summary</span>
      </NavLink>
      <NavLink className="nav-link" to="/input_data_am">
        <MdOutlineInput className='logo-resp'/><span className='menu-text'>Input Data</span>
      </NavLink>
      <NavLink className="nav-link" to="/report_am">
        <IoDocumentText className='logo-resp'/><span className='menu-text'>Report</span>
      </NavLink>
      
      <div className="nav-link">
        <MdManageAccounts className='logo-resp'/><span className="nav-link-drop menu-text" onClick={() => toggle()}>Management   <FaChevronRight className='logo-resp'/></span>
      </div>
      <div className={show === true ? "submenu display" : "submenu"}>
        <NavLink className="nav-link" to="/emp_am">
          <IoPeopleSharp className='icondrop logo-resp'/><span className='menu-text submenudrop'>Employee Data</span>
        </NavLink>
        <NavLink className="nav-link" to="/est_am">
          <BsClipboardData className='icondrop logo-resp'/><span className='menu-text submenudrop'>Estimate Data</span>
        </NavLink>
        <NavLink className="nav-link" to="/profile_am">
          <AiIcons.AiFillProfile className='icondrop'/><span className='menu-text submenudrop'>Profile</span>
        </NavLink>
      </div>
      <div className='divider-navbar'></div>
      {/* <div className="nav-link logout">
        <IoLogOut /><a className="nav-link-drop menu-text" onClick={logout} href='#!'>Logout</a>
      </div> */}
      <NavLink className="logout nav-link" onClick={logout} to="#!">
        <IoLogOut /><span className='menu-text'>Logout</span>
      </NavLink>

    </div>
  )

  const authLinksM = (

    <div id='sidebar'>
      <div className='sidebar-header'>
        <img src={pomaslogo} width="70%" className='center-logo'/>
        {/* <h3>POMAS</h3> */}
      </div>
      <NavLink className="nav-link" to="/dashboard_m">
        <FaChartPie className='logo-resp'/><span className='menu-text'>Dashboard</span>
      </NavLink>
      <NavLink className="nav-link" to="/summary_m">
        <RiBarChartBoxFill className='logo-resp' /><span className='menu-text'>Summary</span>
      </NavLink>
      <NavLink className="nav-link" to="/input_data_m">
        <MdOutlineInput className='logo-resp'/><span className='menu-text'>Input Data</span>
      </NavLink>
      <NavLink className="nav-link" to="/report_m">
        <IoDocumentText className='logo-resp'/><span className='menu-text'>Report</span>
      </NavLink>
      
      <div className="nav-link">
        <MdManageAccounts className='logo-resp'/><span className="nav-link-drop menu-text" onClick={() => toggle()}>Management   <FaChevronRight className='logo-resp'/></span>
      </div>
      <div className={show === true ? "submenu display" : "submenu"}>
        <NavLink className="nav-link" to="/emp_m">
          <IoPeopleSharp className='icondrop logo-resp'/><span className='menu-text submenudrop'>Employee Data</span>
        </NavLink>
        <NavLink className="nav-link" to="/est_m">
          <BsClipboardData className='icondrop logo-resp'/><span className='menu-text submenudrop'>Estimate Data</span>
        </NavLink>
        <NavLink className="nav-link" to="/profile_m">
          <AiIcons.AiFillProfile className='icondrop'/><span className='menu-text submenudrop'>Profile</span>
        </NavLink>
      </div>
      <div className='divider-navbar'></div>
      {/* <div className="nav-link logout">
        <IoLogOut /><a className="nav-link-drop menu-text" onClick={logout} href='#!'>Logout</a>
      </div> */}
      <NavLink className="logout nav-link" onClick={logout} to="#!">
        <IoLogOut /><span className='menu-text'>Logout</span>
      </NavLink>

    </div>
  )

  const guestLinks = (
    <Fragment>
      <NavLink className="nav-link" to="/">Login</NavLink>
      <NavLink className="nav-link" to="/register">Register</NavLink>
    </Fragment>
  )

  return (
    <div className='container-fluid'>
      {isAuthenticated && (isRole === "Clerk" || isRole === 'Senior Asisstant Manager') ? authLinks : isAuthenticated && (isRole === "Assistant Manager" || isRole === "Account") ? authLinksAM : isAuthenticated && (isRole === "Manager" || isRole === 'Executive Charmain' || isRole === 'General Manager') ? authLinksM : null}
    </div>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isRole: state.profile.role
})

export default connect(mapStateToProps, { logout })(Navbar)
