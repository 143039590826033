import {
    EMPLOYEE_DATA_SUCCESS,
    EMPLOYEE_DATA_FAIL,
    EMPLOYESS_UPDATE_SUCCESS,
    EMPLOYESS_UPDATE_FAIL
} from '../actions/types'

const initialState = {
    Employee_id: '',
    Name: '',
    Role: ''
}

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case EMPLOYEE_DATA_SUCCESS:
        case EMPLOYESS_UPDATE_SUCCESS:
            return {
                ...state,
                Employee_id: payload.Employee_id,
                Name: payload.Name,
                Role: payload.Role
            }
        case EMPLOYEE_DATA_FAIL:
        case EMPLOYESS_UPDATE_FAIL:
            return {
                ...state,
                Employee_id: '',
                Name: '',
                Role: ''
            }
        case EMPLOYEE_DATA_FAIL:
        case EMPLOYESS_UPDATE_FAIL:
            return {
                ...state
            }
        default:
            return state
    }
}

