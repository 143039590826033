import React, { useState, useEffect } from 'react'
import { Navigate, Link } from 'react-router-dom'
import axios from 'axios'
import { Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { BiSearch } from "react-icons/bi";
import DayJS from 'react-dayjs'
import Cookies from 'js-cookie'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import '../../node_modules/react-toastify/dist/ReactToastify.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';



function Report() {

    const todayDate = new Date()
    // const getCurrentMonthPlantation = todayDate.getMonth() + 1
    const getCurrentYear = todayDate.getFullYear()

    const currentDate = new Date()
    const currentYear = moment(currentDate).year() - 4
    const currentYearFive = moment(currentDate).year()
    

    const [selectedType, setSelectedType] = useState('Month')
    const [selectedQuarter, setSelectedQuarter] = useState('1Q')
    const [selectedYear, setSelectedYear] = useState(getCurrentYear)
    

    let yearRange = []

    for (let i = currentYear; i <= currentYearFive; i++) {
        const element = i;
        yearRange.push(element)
    }

    function handleSearchTypeChange(event) {
        setSelectedType(event.target.value)
    }

    function handleSearchQuarterChange(event) {
        setSelectedQuarter(event.target.value)
    }

    function handleSelectedYearChange(event) {
        setSelectedYear(event.target.value)
    }


    const [searchPlantation, setSearchPlantation] = useState([])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBlistplant/`)
            .then((response) => {
                setSearchPlantation(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])


    

    const todayDate1 = new Date()
    const [searchDate, setSearchDate] = useState(todayDate1)
    const getMonth = moment(searchDate).month() + 1
    const getYear = moment(searchDate).year()

    const onSubmit = e => {
        if (selectedType === 'Month') {
            e.preventDefault();
            window.open(`${process.env.REACT_APP_API_URL}/LTSB/LTSBpdf/Year=${getYear}/Month=${getMonth}/`);
            console.log(`${process.env.REACT_APP_API_URL}/LTSB/LTSBpdf/Year=${getYear}/Month=${getMonth}/`);
            
        } else if (selectedType === 'Quarter') {
            e.preventDefault();
            window.open(`${process.env.REACT_APP_API_URL}/LTSB/LTSBpdf/Year=${selectedYear}/Quarter=${selectedQuarter}/`);
            console.log(`${process.env.REACT_APP_API_URL}/LTSB/LTSBpdf/Year=${selectedYear}/Quarter=${selectedQuarter}/`);
             
        } else if (selectedType === 'Year') {
            e.preventDefault();
            // window.open(`${process.env.REACT_APP_API_URL}/LTSB/LTSBpdf/Year=${getYear}/Quarter=${getMonth}/`);
            console.log(`${process.env.REACT_APP_API_URL}/LTSB/LTSBpdf/Year=${selectedYear}/`);
            
        }
        // e.preventDefault();
        // window.open(`${process.env.REACT_APP_API_URL}/LTSB/LTSBpdf/Year=${getYear}/Month=${getMonth}/`);
        // console.log('ayam')
    }

    return (

        <div className='container-fluid content-container mt-5'>
            <h1 className='mt-3 mb-3'><b>Report</b></h1>
            <h6 className="text-left mt-3">Filter By:  </h6>
            <div className='form-group d-flex'>
                <div>
                    <label className='label-summary' htmlFor='estate'>Type: </label>
                    <select className="form-input-summary" value={selectedType} onChange={handleSearchTypeChange}>
                        <option className='form-input-summary' value="Month">Month</option>
                        <option className='form-input-summary' value="Quarter">Quarter</option>
                        <option className='form-input-summary' value="Year">Year</option>
                    </select>
                </div>
                {
                    selectedType === 'Month' ? (
                        <div className='form-group d-flex'>
                            {/* <div>
                                <label className='label-summary' htmlFor='estate'>Plantation: </label>
                                <select className="form-input-summary" value={selectedPlantation} onChange={handleSearchPlantationChange}>
                                    {
                                        searchPlantation.map((plant) => {
                                            return (
                                                <option className='form-input-summary' value={plant.Name}>{plant.Name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div> */}
                            <div className='d-flex'>
                                <label className='row justify-content-center align-self-center mx-2' htmlFor='month_year'>Month/Year: </label>
                                <DatePicker
                                    selected={searchDate}
                                    onChange={setSearchDate}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    showFullMonthYearPicker
                                    className="form-input-summary"
                                />
                            </div>
                        </div>
                    ) : selectedType === 'Quarter' ? (
                        <div className='form-group d-flex'>
                            {/* <div>
                                <label className='label-summary' htmlFor='estate'>Plantation: </label>
                                <select className="form-input-summary" value={selectedPlantation} onChange={handleSearchPlantationChange}>
                                    {
                                        searchPlantation.map((plant) => {
                                            return (
                                                <option className='form-input-summary' value={plant.Name}>{plant.Name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div> */}
                            <div>
                                <label className='label-summary' htmlFor='quarter'>Quarter: </label>
                                <select className="form-input-summary" value={selectedQuarter} onChange={handleSearchQuarterChange}>
                                    <option className='form-input-summary' value="1Q">1Q</option>
                                    <option className='form-input-summary' value="2Q">2Q</option>
                                    <option className='form-input-summary' value="3Q">3Q</option>
                                    <option className='form-input-summary' value="4Q">4Q</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label className='label-summary' htmlFor='division'>Year:</label>
                                <select className="form-input-summary" value={selectedYear} onChange={handleSelectedYearChange}>
                                    {
                                        yearRange.map((x) => {
                                            return (
                                                <option value={x}>{x}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    ) : (
                        <div className='form-group d-flex'>
                            {/* <div>
                                <label className='label-summary' htmlFor='estate'>Plantation: </label>
                                <select className="form-input-summary" value={selectedPlantation} onChange={handleSearchPlantationChange}>
                                    {
                                        searchPlantation.map((plant) => {
                                            return (
                                                <option className='form-input-summary' value={plant.Name}>{plant.Name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div> */}
                            <div className="form-group">
                                <label className='label-summary' htmlFor='year'>Year:</label>
                                <select className="form-input-summary" value={selectedYear} onChange={handleSelectedYearChange}>
                                    {
                                        yearRange.map((x) => {
                                            return (
                                                <option value={x}>{x}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    )
                }
                <button className='btn btn-primary' onClick={e => onSubmit(e)}>Generate Report</button>
            </div>
        </div>


        // <div className='container mt-5'>
        //     <h1 className='mt-3 mb-3'><b>Report</b></h1>
        //     <h6 className="text-left mt-3">Filter By:  </h6>
        //     <div className='form-group d-flex justify-content-start'>

        //         <div className="d-flex dashboard mt-1">
        //             <label className='form-label label-summary' htmlFor='estate'>Plantation: </label>
        //             <select className="form-input-summary" value={selectedType} onChange={handleSearchTypeChange}>
        //                 <option className='form-input-summary' value="Month">Month</option>
        //                 <option className='form-input-summary' value="Quarter">Quarter</option>
        //                 <option className='form-input-summary' value="Year">Year</option>
        //             </select>
        //         </div>
        //         <div className="d-flex dashboard mt-1">
        //             <label className='form-label label-summary monthyear' htmlFor='estate'>Month/Year: </label>
        //             <DatePicker
        //                 selected={searchDate}
        //                 onChange={setSearchDate}
        //                 dateFormat="MM/yyyy"
        //                 showMonthYearPicker
        //                 showFullMonthYearPicker
        //                 className="form-input-summary"
        //             />
        //         </div>
        //         <button className='btn btn-primary' onClick={e => onSubmit(e)}>Click me</button>

        //     </div>

        // </div>


    )
}


export default Report