import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { add_est_data } from '../actions/estimateForm'
import axios from 'axios'
import { ToastContainer } from 'react-toastify'
import '../../node_modules/react-toastify/dist/ReactToastify.css'
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setYear } from 'date-fns'
import { useNavigate } from 'react-router-dom'

function AddEstimateData({
    add_est_data,
    first_name_global,
    last_name_global,
    company_global,
    role_global,
    estate_global,
    employee_id_global
}) {

    const [divs, setDivs] = useState([])
    const [estates, setEstate] = useState([])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBlistdiv/`)
            .then((response) => {
                setDivs(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBlistplant/`)
            .then((response) => {
                setEstate(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    const [selectedDivision, setSelectedDivision] = useState('Choose...'); //default value
    const [selectedEstate, setSelectedEstate] = useState('Choose...'); //default value
    const [estId, setestId] = useState('')
    // const [selectedMY, setSelectedMY] = useState('')
    const [selectedMonth, setSelectedMonth] = useState('')
    const [selectedYear, setSelectedYear] = useState('')
    // const [yearRange, setYearRange] = useState([])
    const [estWeight, setEstWeight] = useState('')
    const [estHectare, setEstHectare] = useState('')

    // let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    let months = [
        {
            key: 1, month: "January"
        },
        {
            key: 2, month: "February"
        },
        {
            key: 3, month: "March"
        },
        {
            key: 4, month: "April"
        },
        {
            key: 5, month: "May"
        },
        {
            key: 6, month: "June"
        },
        {
            key: 7, month: "July"
        },
        {
            key: 8, month: "August"
        },
        {
            key: 9, month: "September"
        },
        {
            key: 10, month: "October"
        },
        {
            key: 11, month: "November"
        },
        {
            key: 12, month: "December"
        },
    ]

    const currentDate = new Date()
    const currentYear = moment(currentDate).year() - 5
    const currentYearFive = moment(currentDate).year() + 5
    let yearRange = []

    for (let i = currentYear; i < currentYearFive; i++){
        const element = i;
        console.log(element)
        yearRange.push(element)
    }

    function handleIdChange(event) {
        setestId(event.target.value)
    }

    function handleSelectEstateChange(event) {
        setSelectedEstate(event.target.value)
    }

    function handleSelectedDivChange(event) {
        setSelectedDivision(event.target.value)
    }

    function handleSelectedYearChange(event) {
        setSelectedYear(event.target.value)
    }

    function handleSelectedMonthChange(event) {
        setSelectedMonth(event.target.value)
    }


    function handleEstimatedChange(event) {
        setEstWeight(event.target.value)
    }

    function handleHectareChange(event) {
        setEstHectare(event.target.value)
    }

    // const getMonth = moment(selectedMY).month() + 1
    // const getYear = moment(selectedMY).year()

    const onSubmit = e => {
        e.preventDefault();
        add_est_data(selectedEstate, selectedDivision, selectedYear, selectedMonth, estWeight, estHectare)
        setSelectedEstate('')
        setSelectedDivision('')
        setSelectedYear('')
        setSelectedMonth('')
        setEstWeight('')
        setEstHectare('')
        setTimeout(function () {
            if (role_global === 'Assistant Manager') {
              navigate('/est_am')
            } else if (role_global === 'Manager') {
              navigate('/est_m')
            } else if (role_global === 'Clerk') {
              navigate('/est')
            }
        }, 4000);
    }

    console.log(selectedEstate)
    console.log(selectedDivision)
    console.log(selectedYear)
    console.log(selectedMonth)
    console.log(estWeight)
    console.log(estHectare)

    let navigate = useNavigate()

    const cancel = e => {
        if (role_global === 'Assistant Manager') {
          e.preventDefault();
          navigate('/est_am')
        } else if (role_global === 'Manager') {
          e.preventDefault();
          navigate('/est_m')
        } else if (role_global === 'Clerk') {
          e.preventDefault();
          navigate('/est')
        }
    
      }

    return (
        <div className="container mt-3">
            <form onSubmit={e => onSubmit(e)}>
                <div className="w-75 mx-auto shadow p-5">
                    <h2 className="text-center mb-4">Estimate Data : </h2>

                    <div className="form-group">
                        <label className='form-label mt-3' htmlFor='estate'>Estate</label>
                        <select className="form-control" value={selectedEstate} onChange={handleSelectEstateChange}>
                            <option value="">Choose...</option>
                            {
                                estates.map((estate) => {
                                    return (
                                        <option value={estate.Name}>{estate.Name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>

                    <div className="form-group">
                        <label className='form-label mt-3' htmlFor='division'>Division : </label>
                        <select className="form-control" value={selectedDivision} onChange={handleSelectedDivChange}>
                            <option value="">Choose...</option>
                            {
                                divs.map((div) => {
                                    return (
                                        <option value={div.Name}>{div.Name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>

                    <div className="form-group">
                        <label className='form-label mt-3' htmlFor='division'>Year : </label>
                        <select className="form-control" value={selectedYear} onChange={handleSelectedYearChange}>
                            <option value="">Choose...</option>
                            {
                                yearRange.map((x) => {
                                    return (
                                        <option value={x}>{x}</option>
                                    )
                                })
                            }
                        </select>
                    </div>

                    <div className="form-group">
                        <label className='form-label mt-3' htmlFor='division'>Month : </label>
                        <select className="form-control" value={selectedMonth} onChange={handleSelectedMonthChange}>
                            <option value="">Choose...</option>
                            {
                                months.map((x) => {
                                    return (
                                        <option value={x.key}>{x.month}</option>
                                    )
                                })
                            }
                        </select>
                    </div>

                    {/* <div className="form-group d-flex mb-3 mt-3">
                        <label className='form-label' htmlFor='estate'>Month and Year : </label>
                        <DatePicker
                            selected={selectedMY}
                            onChange={setSelectedMY}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            showFullMonthYearPicker
                            className='form-control'
                        />
                    </div> */}

                    <div className="form-group">
                        <label className='form-label mt-3' htmlFor='weight'>Estimate Weight Per Day {"(Mt)"} : </label>
                        <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Estimated Weight Per Day"
                            name="weight"
                            value={estWeight}
                            onChange={handleEstimatedChange}
                        />
                    </div>

                    <div className="form-group">
                        <label className='form-label mt-3' htmlFor='weight'>Hectare : </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Hectare"
                            name="employee_role"
                            value={estHectare}
                            onChange={handleHectareChange}
                        />
                    </div>

                    <button className="btn btn-primary btn-block mt-3" type='submit'>Add Data</button>
                    <button className="btn btn-dark btn-block mt-3 mx-3" onClick={e => cancel(e)}>Cancel</button>
                </div>
            </form>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )
}

const mapStateToProps = state => ({
    first_name_global: state.profile.first_name,
    last_name_global: state.profile.last_name,
    company_global: state.profile.company,
    role_global: state.profile.role,
    estate_global: state.profile.estate,
    employee_id_global: state.profile.employee_id
})

export default connect(mapStateToProps, { add_est_data, })(AddEstimateData)