import React, { useState } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate } from 'react-router-dom'
import { Chart as ChartJS, registerables } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2'
import { Modal, Button } from 'react-bootstrap'
ChartJS.register(...registerables);

function MachineryDashboard() {

  const [modalCheckOut, setModalCheckOut] = useState(false)
  const [modalCheckIn, setModalCheckIn] = useState(false)

  let navigate = useNavigate()
  const data = {
    labels: [
      'Tractor',
      'Lorry',
    ],
    datasets: [
      {
        label: 'My First Dataset',
        data: [300, 50],
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(54, 162, 235)',
        ],
        hoverOffset: 4,
        borderWidth: 1,
      }
    ]
  };

  const data_bar = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    datasets: [
      {
        backgroundColor: 'rgba(255,0,0)',
        data: [3, 4, 5, 9, 2, 0, 0],
      },
    ]
  }

  const options_bar = {
    scales: {
      yAxes:
      {
        grid: {
          drawBorder: true,
          color: '#FFFFFF',
        },
        ticks: {
          beginAtZero: true,
          color: 'white',
          fontSize: 12,
        }
      },
      xAxes:
      {
        grid: {
          drawBorder: true,
          color: '#FFFFFF',
        },
        ticks: {
          beginAtZero: true,
          color: 'white',
          fontSize: 12,
        }
      }
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const value = 66;
  const max = 100
  const machinery_link = 'mc_list'

  const changeRoute = e => {
    e.preventDefault();
    navigate('/mc_list');
    window.scrollTo(0, 0)
  }

  const showPopup = e => {
    setModalCheckOut(true)
  }

  const showPopupCheckIn = e => {
    setModalCheckIn(true)
  }

  const submitForm = e => {
    console.log("success")
  }

  const submitFormCheckIn = e => {
    console.log("success Check In")
  }

  const handleClose = () => setModalCheckOut(false);
  const handleCloseCheckIn = () => setModalCheckIn(false);

  return (
    <>
      <Modal show={modalCheckOut} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header>
          <Modal.Title>
            Check out for MCB123
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className='form-group'>
              <label htmlFor="">Check out Time</label>
              <input type="time" />
            </div>
            <div className='form-group'>
              <label htmlFor="">Check out Mileage</label>
              <input type="number" />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button className='btn-primary' onClick={(e) => submitForm(e)}>
            Submit
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={modalCheckIn} onHide={handleCloseCheckIn} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header>
          <Modal.Title>
            Check out for MCB123
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className='form-group'>
              <label htmlFor="" className='form-label'>Check In Time</label>
              <input type="time" className='form-control' />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button className='btn-primary' onClick={(e) => submitFormCheckIn(e)}>
            Submit
          </Button>
          <Button variant="secondary" onClick={handleCloseCheckIn}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className='container-fluid mt-5 content-container'>
        <div className='row mc-main mx-auto justify-content-center'>
          <div className='col-4 p-3'>
            <div className='container-mc-overview container rounded-5 mc-inactive'>
              <div >
                <Pie
                  data={data}
                />
              </div>
              <div className='mc-content'>
                <h4 className='text-light font-weight-bold text-center'>Inactive</h4>
                <h5 className='text-light font-weight-bold text-center'>Tractor: 20</h5>
                <h5 className='text-light font-weight-bold text-center'>Trktor: 24</h5>
              </div>
            </div>
          </div>
          <div className='col-4 p-3'>
            <div className='container-mc-overview container rounded-5 mc-running'>
              <div >
                <Pie
                  data={data}
                />
              </div>
              <div className='mc-content'>
                <h4 className='text-light font-weight-bold text-center'>Running</h4>
                <h5 className='text-light font-weight-bold text-center'>Tractor: 20</h5>
                <h5 className='text-light font-weight-bold text-center'>Trktor: 24</h5>
              </div>
            </div>
          </div>
        </div>

        <div className='row mc-main mx-auto justify-content-center'>
          <div className='col-4 p-3'>
            <div className='container-mc-overview container rounded-5 mc-repair'>
              <div >
                <Pie
                  data={data}
                />
              </div>
              <div className='mc-content'>
                <h4 className='text-light font-weight-bold text-center'>In-Repair</h4>
                <h5 className='text-light font-weight-bold text-center'>Tractor: 20</h5>
                <h5 className='text-light font-weight-bold text-center'>Trktor: 24</h5>
              </div>
            </div>
          </div>
          <div className='col-4 p-3'>
            <div className='container-mc-overview container rounded-5 mc-break'>
              <div >
                <Pie
                  data={data}
                />
              </div>
              <div className='mc-content'>
                <h4 className='text-light font-weight-bold text-center'>Breakdown</h4>
                <h5 className='text-light font-weight-bold text-center'>Tractor: 20</h5>
                <h5 className='text-light font-weight-bold text-center'>Trktor: 24</h5>
              </div>
            </div>
          </div>
        </div>


        <div className='my-5'>
          <div className='my-3 d-flex justify-content-center'>
            <h3>Tractor</h3>
          </div>
          <div className='row mc-main mx-auto mt-5 mb-5'>

            <div className='col-3 p-2'>
              <div className='mc-list p-2 mc-running'>
                <div className='mc-content'>
                  <div className='p-3 mc-content-child'>
                    <CircularProgressbar
                      value={value}
                      maxValue={max}
                      text={`${value} out off ${max} days`}
                      styles={{
                        // Customize the root svg element
                        root: {},
                        // Customize the path, i.e. the "completed progress"
                        path: {
                          // Path color
                          stroke: `rgb(85, 216, 85)`,
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: 'rounded',
                          // Customize transition animation
                          transition: 'stroke-dashoffset 0.5s ease 0s',
                          // Rotate the path
                          transformOrigin: 'center center',
                        },
                        // Customize the circle behind the path, i.e. the "total progress"
                        trail: {
                          // Trail color
                          stroke: '#f5f0f0',
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: 'rounded',
                          // Rotate the trail
                          transformOrigin: 'center center',
                        },
                        // Customize the text
                        text: {
                          // Text color
                          fill: '#ffffff',
                          // Text size
                          fontSize: '9px',
                        },
                        // Customize background - only used when the `background` prop is true
                        background: {
                          fill: '#3e98c7',
                        },
                      }} />
                  </div>
                  <div className='license-plate p-2 mb-2'>
                    <h6 className='text-center font-weight-bold'>MCB123</h6>
                  </div>
                  <p className='text-light font-weight-bold text-center'>Days running: 10</p>
                  <p className='text-light font-weight-bold text-center'>status: Running</p>
                  <div className='d-flex justify-content-around'>
                    <p className='text-light font-weight-bold text-center'>time in: 8.00AM</p>
                    <p className='text-light font-weight-bold text-center'>time out: ----</p>
                  </div>
                  <div className='d-flex justify-content-around'>
                    <p className='text-light font-weight-bold text-center'>Meter in: 55555KM</p>
                    <p className='text-light font-weight-bold text-center'>Meter out: ----</p>
                  </div>
                  <div>
                    <Bar
                      data={data_bar}
                      options={options_bar}
                    />
                  </div>
                  <div className='d-flex my-3 justify-content-around'>
                    <button className='btn btn-primary' onClick={(e) => showPopup(e)}>Check Out</button>
                    <button className='btn btn-primary' onClick={(e) => changeRoute(e)}>See Details</button>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-3 p-2'>
              <div className='mc-list p-2 mc-inactive'>
                <div className='mc-content'>
                  <div className='p-3'>
                    <CircularProgressbar
                      value={value}
                      maxValue={max}
                      text={`${value} out off ${max} days`}
                      styles={{
                        // Customize the root svg element
                        root: {},
                        // Customize the path, i.e. the "completed progress"
                        path: {
                          // Path color
                          stroke: `rgb(85, 216, 85)`,
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: 'rounded',
                          // Customize transition animation
                          transition: 'stroke-dashoffset 0.5s ease 0s',
                          // Rotate the path
                          transformOrigin: 'center center',
                        },
                        // Customize the circle behind the path, i.e. the "total progress"
                        trail: {
                          // Trail color
                          stroke: '#f5f0f0',
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: 'rounded',
                          // Rotate the trail
                          transformOrigin: 'center center',
                        },
                        // Customize the text
                        text: {
                          // Text color
                          fill: '#ffffff',
                          // Text size
                          fontSize: '9px',
                        },
                        // Customize background - only used when the `background` prop is true
                        background: {
                          fill: '#3e98c7',
                        },
                      }} />
                  </div>
                  <div className='license-plate p-2 mb-2'>
                    <h6 className='text-center font-weight-bold'>MCB12345</h6>
                  </div>
                  <p className='text-light font-weight-bold text-center'>Days running: 10</p>
                  <p className='text-light font-weight-bold text-center'>status: Inactive</p>
                  <div className='d-flex justify-content-around'>
                    <p className='text-light font-weight-bold text-center'>time in: ----</p>
                    <p className='text-light font-weight-bold text-center'>time out: ----</p>
                  </div>
                  <div className='d-flex justify-content-around'>
                    <p className='text-light font-weight-bold text-center'>Meter in: ----</p>
                    <p className='text-light font-weight-bold text-center'>Meter out: ----</p>
                  </div>
                  <div>
                    <Bar
                      data={data_bar}
                      options={options_bar}
                    />
                  </div>
                  <div className='d-flex my-3 justify-content-around'>
                    <button className='btn btn-primary' onClick={(e) => showPopupCheckIn(e)}>Check In</button>
                    <button className='btn btn-primary' onClick={(e) => changeRoute(e)}>See Details</button>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-3 p-2'>
              <div className='mc-list p-2 mc-repair'>
                <div className='mc-content'>
                  <div className='p-3'>
                    <CircularProgressbar
                      value={value}
                      maxValue={max}
                      text={`${value} out off ${max} days`}
                      styles={{
                        // Customize the root svg element
                        root: {},
                        // Customize the path, i.e. the "completed progress"
                        path: {
                          // Path color
                          stroke: `rgb(216, 216, 47)`,
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: 'rounded',
                          // Customize transition animation
                          transition: 'stroke-dashoffset 0.5s ease 0s',
                          // Rotate the path
                          transformOrigin: 'center center',
                        },
                        // Customize the circle behind the path, i.e. the "total progress"
                        trail: {
                          // Trail color
                          stroke: '#f5f0f0',
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: 'rounded',
                          // Rotate the trail
                          transformOrigin: 'center center',
                        },
                        // Customize the text
                        text: {
                          // Text color
                          fill: '#ffffff',
                          // Text size
                          fontSize: '9px',
                        },
                        // Customize background - only used when the `background` prop is true
                        background: {
                          fill: '#3e98c7',
                        },
                      }} />
                  </div>
                  <div className='license-plate p-2 mb-2'>
                    <h6 className='text-center font-weight-bold'>KJS1230</h6>
                  </div>
                  <p className='text-light font-weight-bold text-center'>Days running: 10</p>
                  <p className='text-light font-weight-bold text-center'>status: In Repair</p>
                  <div className='d-flex justify-content-around'>
                    <p className='text-light font-weight-bold text-center'>Repair start: 12/3/2022 8.00AM</p>
                  </div>
                  <div className='d-flex justify-content-around'>
                    <p className='text-light font-weight-bold text-center'>Meter in Repair: 55555KM</p>
                  </div>
                  <div>
                    <Bar
                      data={data_bar}
                      options={options_bar}
                    />
                  </div>
                  <div className='d-flex my-3 justify-content-around'>
                    <button className='btn btn-primary' onClick={(e) => showPopup(e)}>Check Out</button>
                    <button className='btn btn-primary' onClick={(e) => changeRoute(e)}>See Details</button>
                  </div>
                </div>
              </div>
            </div>


            <div className='col-3 p-2'>
              <div className='mc-list p-2 mc-break'>
                <div className='mc-content'>
                  <div className='p-3'>
                    <CircularProgressbar
                      value={value}
                      maxValue={max}
                      text={`${value} out off ${max} days`}
                      styles={{
                        // Customize the root svg element
                        root: {},
                        // Customize the path, i.e. the "completed progress"
                        path: {
                          // Path color
                          stroke: `rgb(214, 39, 39)`,
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: 'rounded',
                          // Customize transition animation
                          transition: 'stroke-dashoffset 0.5s ease 0s',
                          // Rotate the path
                          transformOrigin: 'center center',
                        },
                        // Customize the circle behind the path, i.e. the "total progress"
                        trail: {
                          // Trail color
                          stroke: '#f5f0f0',
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: 'rounded',
                          // Rotate the trail
                          transformOrigin: 'center center',
                        },
                        // Customize the text
                        text: {
                          // Text color
                          fill: '#ffffff',
                          // Text size
                          fontSize: '9px',
                        },
                        // Customize background - only used when the `background` prop is true
                        background: {
                          fill: '#3e98c7',
                        },
                      }} />
                  </div>
                  <div className='license-plate p-2 mb-2'>
                    <h6 className='text-center font-weight-bold'>MSD1345</h6>
                  </div>
                  <p className='text-light font-weight-bold text-center'>Days running: 10</p>
                  <p className='text-light font-weight-bold text-center'>status: Broken</p>
                  <div className='d-flex'>
                    <p className='text-light font-weight-bold text-center'>Break at: 12/3/2022 8.00AM</p>
                  </div>
                  <div className='d-flex'>
                    <p className='text-light font-weight-bold text-center'>Meter when start break: 55555KM</p>
                  </div>
                  <div>
                    <Bar
                      data={data_bar}
                      options={options_bar}
                    />
                  </div>
                  <div className='d-flex my-3 justify-content-around'>
                    <button className='btn btn-primary' onClick={(e) => showPopup(e)}>Check Out</button>
                    <button className='btn btn-primary' onClick={(e) => changeRoute(e)}>See Details</button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </>
  )
}

export default MachineryDashboard