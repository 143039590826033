import {
    INPUT_DATA_UPDATE_SUCCESS,
    INPUT_DATA_UPDATE_FAIL,
    UPDATE_DATA_SUCCESS,
    UPDATE_DATA_FAIL
} from '../actions/types'

const initialState = {
    DO_NO: '',
    Date: '',
    Plantation: '',
    Division: '',
    Mill: '',
    Clerk: '',
    Driver: '',
    Vehicle_No: '',
    TimeIN: '',
    TimeOUT: '',
    First_Weight: '',
    Second_Weight: '',
    Today_NetWeight: '',
    Remarks: ''
}

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case INPUT_DATA_UPDATE_SUCCESS:
        case UPDATE_DATA_SUCCESS:
            return {
                ...state,
                DO_NO: payload.DO_NO,
                Date: payload.Date,
                Plantation: payload.Plantation,
                Division: payload.Division,
                Mill: payload.Mill,
                Clerk: payload.Clerk,
                Driver: payload.Driver,
                Vehicle_No: payload.Vehicle_No,
                TimeIN: payload.TimeIN,
                TimeOUT: payload.TimeOUT,
                First_Weight: payload.First_Weight,
                Second_Weight: payload.Second_Weight,
                Today_NetWeight: payload.Today_NetWeight,
                Remarks: payload.Remarks
            }
        case INPUT_DATA_UPDATE_FAIL:
        case UPDATE_DATA_FAIL:
            return {
                ...state,
                id: '',
                estate: '',
                division: '',
                weight: '',
                date: '',
                DO_NO: '',
                Date: '',
                Plantation: '',
                Division: '',
                Mill: '',
                Clerk: '',
                Driver: '',
                Vehicle_No: '',
                TimeIN: '',
                TimeOUT: '',
                First_Weight: '',
                Second_Weight: '',
                Today_NetWeight: '',
                Remarks: ''
            }
        case INPUT_DATA_UPDATE_FAIL:
            return {
                ...state
            }
        default:
            return state
    }
}

