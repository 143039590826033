import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Navigate, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { register } from '../actions/auth'
import CSRFToken from '../components/CSRFToken'

function Register({ register, isAuthenticated }) {

  const [formData, setFormData] = useState({
    user_name: '',
    password: '',
    re_password: '',
    email: '',
    first_name: '',
    last_name: '',
  })

  const [accountCreated, setAccountCreated] = useState(false)
  const [role, setRole] = useState([])
  const [estate, setEstate] = useState([])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/accounts/role/`)
      .then((response) => {
        setRole(response.data)
      })
      .catch(error => console.error(`Error: ${error}`))
  }, [])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/accounts/estate/`)
      .then((response) => {
        setEstate(response.data)
      })
      .catch(error => console.error(`Error: ${error}`))
  }, [])

  const { user_name, password, re_password, email, first_name, last_name } = formData

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

  const [roleSelected, setRoleSelected] = useState('')
  const [empId, setEmpId] = useState('')
  const [estateSelected, setEstateSelected] = useState('')

  function handleRoleChange(event) {
    setRoleSelected(event.target.value)
  }

  function handleEstateChange(event) {
    setEstateSelected(event.target.value)
  }

  function handleEmpID(event) {
    setEmpId(event.target.value)
  }

  const onSubmit = e => {
    e.preventDefault();

    if (password === re_password) {
      register(user_name, password, re_password, email, first_name, last_name, roleSelected, estateSelected, empId);
      setAccountCreated(true)
    }
  }

  console.log(roleSelected)
  console.log(empId)

  if (isAuthenticated)
    return <Navigate to="/dashboard" />
  else if (accountCreated)
    return <Navigate to="/" />


  return (
    <div className='container mt-5'>
      <h1>Register for an Account</h1>
      <p>Create an account for POMAS</p>
      <form onSubmit={e => onSubmit(e)}>
        <CSRFToken />
        <div className='form-group'>
          <label className='form-label mt-3'>Role:</label>
          <select className="form-control login-form" value={roleSelected} onChange={handleRoleChange} required>
            <option className='option-text' value="">Choose...</option>
            {
              role.map((x) => {
                return (
                  <option className='option-text' value={x.key}>{x.value}</option>
                )
              })
            }
          </select>
        </div>
        <div className='form-group' >
          <label className='form-label mt-3'>Employee ID:</label>
          <input className='form-control' type='text' placeholder='Employee ID*' name='empId' onChange={handleEmpID} value={empId} required />
        </div>
        <div className='form-group'>
          <label className='form-label mt-3'>Username:</label>
          <input className='form-control' type='text' placeholder='Username*' name='user_name' onChange={e => onChange(e)} value={user_name} required />
        </div>
        <div className='form-group'>
          <label className='form-label mt-3'>Password:</label>
          <input className='form-control' type='password' placeholder='Password*' name='password' onChange={e => onChange(e)} value={password} minLength='6' required />
        </div>
        <div className='form-group'>
          <label className='form-label mt-3'>Confirm Password:</label>
          <input className='form-control' type='password' placeholder='Confirm Password*' name='re_password' onChange={e => onChange(e)} value={re_password} minLength='6' required />
        </div>
        <div className='form-group'>
          <label className='form-label mt-3'>Email:</label>
          <input className='form-control login-form' type='email' placeholder='Email*' name='email' onChange={e => onChange(e)} value={email} required />
        </div>
        <div className='d-flex justify-content-between'>
          <div className='form-group w-100'>
            <label className='form-label mt-3'>First Name:</label>
            <input className='form-control login-form register-form-name' type='text' placeholder='First Name*' name='first_name' onChange={e => onChange(e)} value={first_name} required />
          </div>
          <div className='form-group w-100'>
            <label className='form-label mt-3'>Last Name:</label>
            <input className='form-control login-form register-form-name' type='text' placeholder='Last Name*' name='last_name' onChange={e => onChange(e)} value={last_name} required />
          </div>
        </div>
        <div className='form-group'>
          <label className='form-label mt-3'>Estate:</label>
          <select className="form-control login-form" value={estateSelected} onChange={handleEstateChange} required>
            <option className='option-text' value="">Choose...</option>
            {
              estate.map((x) => {
                return (
                  <option className='option-text' value={x.key}>{x.value}</option>
                )
              })
            }
            <option className='option-text' value="All">All</option>
          </select>
        </div>
        <button className='btn btn-primary mt-3' type='submit'>Register</button>
      </form>
      <p className='mt-3'>Already have an account? <Link to='/'>Sign In</Link></p>
    </div>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, { register })(Register)