import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Navigate, Link } from 'react-router-dom'
import axios from 'axios'
import { Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { BiSearch } from "react-icons/bi";

const PER_PAGE = 10

function Employee({
  first_name_global,
  last_name_global,
  company_global,
  role_global,
  estate_global,
  employee_id_global
}) {

  const [getDatas, setGetDatas] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [searchID, setSearchID] = useState("")

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBlistemp/`)
      .then((response) => {
        setGetDatas(response.data)
      })
      .catch(error => console.error(`Error: ${error}`))
  }, [])


  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage)
  }

  const offset = currentPage * PER_PAGE

  const currentPageData = getDatas
    .slice(offset, offset + PER_PAGE)

  const pageCount = Math.ceil(getDatas.length / PER_PAGE)

  const filteredData = getDatas.filter(data => data.Employee_id === searchID)
  const currentPageFilterData = filteredData.slice(offset, offset + PER_PAGE)

  const pageCountFilter = Math.ceil(filteredData.length / PER_PAGE)

  return (
    <div className='container-fluid content-container mt-5'>
      {
        role_global === 'Clerk' ? (
          <Link to="/emp_form" className='btn btn-primary'>Add Employee</Link>
        ) : role_global === "Assistant Manager" ? (
          <Link to="/emp_form_am" className='btn btn-primary'>Add Employee</Link>
        ) : role_global === "Manager" ? (
          <Link to="/emp_form_m" className='btn btn-primary'>Add Employee</Link>
        ) : null
      }
      <h6 className="text-left mb-3 mt-3">Filter By:</h6>
      <div className='form-group d-flex'>
        <div>
          <label htmlFor="date-filter">ID:</label>
          <input type="text" placeholder='Employee ID' className='form-input-summary' name="employee-id" value={searchID} onChange={event => { setSearchID(event.target.value) }} />
        </div>
      </div>
      <div className='container-fluid mt-5'>
        <Table pagination={true}>
          <thead>
            <tr>
              <th>Employee ID</th>
              <th>Name</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {
              searchID === "" ? (
                currentPageData.map((getData) => {
                  if (getData.Employee_id === "NONE") {
                    return null
                    
                  } else {
                    return (
                      <tr>
                        <td>{getData.Employee_id}</td>
                        <td>{getData.Name}</td>
                        <td>{getData.Role}</td>
                        {
                          role_global === "Clerk" ? (
                            <td><Link className='btn btn-primary' to={`/emp_update/${getData.Employee_id}`}>Edit</Link></td>
                          ) : role_global === "Assistant Manager" ? (
                            <td><Link className='btn btn-primary' to={`/emp_update_am/${getData.Employee_id}`}>Edit</Link></td>
                          ) : role_global === "Manager" ? (
                            <td><Link className='btn btn-primary' to={`/emp_update_m/${getData.Employee_id}`}>Edit</Link></td>
                          ): null
                        }
                      </tr>
                    )
                    
                  }
                 
                })
              ) : (
                currentPageFilterData.map((getData) => {
                  if (getData.Employee_id === "NONE") {
                    return null
                    
                  } else {
                    return (
                      <tr>
                        <td>{getData.Employee_id}</td>
                        <td>{getData.Name}</td>
                        <td>{getData.Role}</td>
                        {
                          role_global === "Clerk" ? (
                            <td><Link className='btn btn-primary' to={`/emp_update/${getData.Employee_id}`}>Edit</Link></td>
                          ) : role_global === "Assistant Manager" ? (
                            <td><Link className='btn btn-primary' to={`/emp_update_am/${getData.Employee_id}`}>Edit</Link></td>
                          ) : role_global === "Manager" ? (
                            <td><Link className='btn btn-primary' to={`/emp_update_m/${getData.Employee_id}`}>Edit</Link></td>
                          ): null
                        }
                      </tr>
                    )
                    
                  }

                })
              )
            }
          </tbody>
        </Table>
      </div>
      {
        searchID === "" ? (
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            containerClassName={"pagination"}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            activeClassName={'active'}
          />
        ) : (
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCountFilter}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            containerClassName={"pagination"}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            activeClassName={'active'}
          />
        )
      }

    </div >

  )
}

const mapStateToProps = state => ({
  first_name_global: state.profile.first_name,
  last_name_global: state.profile.last_name,
  company_global: state.profile.company,
  role_global: state.profile.role,
  estate_global: state.profile.estate,
  employee_id_global: state.profile.employee_id
})

export default connect(mapStateToProps, {})(Employee)