import Cookies from 'js-cookie'
import axios from 'axios'
import { load_user } from './profile';
import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    DELETE_USER_FAIL,
    DELETE_USER_SUCCESS
} from './types';
import { toast } from 'react-toastify'

export const checkAuthenticated = () => async dispatch => {
    const config = {
        headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/accounts/authenticated/`, config)

       if (res.data.error || res.data.isAuthenticated === 'error'){
           dispatch({
               type: AUTHENTICATED_FAIL,
               payload: false
           });
       }
       else if (res.data.isAuthenticated === 'success Clerk'){
           dispatch({
               type: AUTHENTICATED_SUCCESS,
               payload: true
           })
       }
       else if (res.data.isAuthenticated === 'success Assistant Manager'){
        dispatch({
            type: AUTHENTICATED_SUCCESS,
            payload: true
        })
    }
    else if (res.data.isAuthenticated === 'success Manager'){
        dispatch({
            type: AUTHENTICATED_SUCCESS,
            payload: true
        })
    }
       else {
        dispatch({
            type: AUTHENTICATED_FAIL,
            payload: false
        })
    }
    } catch (err) {
        dispatch({
            type: AUTHENTICATED_FAIL,
            payload: false
        })
    }
}

export const login = (user_name, password) => async dispatch => {
    const config = {
        headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
    }

    const body = JSON.stringify({user_name, password})

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/accounts/login/`, body, config)

        if (res.data.success) {
            dispatch({
                type : LOGIN_SUCCESS
            })

            dispatch(load_user())
        } else {
            dispatch({
                type: LOGIN_FAIL
            })
            toast.error(res.data.error, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    } catch (err) {
        dispatch({
            type: LOGIN_FAIL
        })
    }
}

export const register = (user_name, password, re_password, email, first_name, last_name, role, estate, empid) => async dispatch => {
    const config = {
        headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
    }
    const body = JSON.stringify({user_name, password, re_password, email, first_name, last_name, role, estate, empid})

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/accounts/register/`, body, config);

        if (res.data.error) {
            dispatch({
                type: REGISTER_FAIL
            })
        } else {
            dispatch({
                type: REGISTER_SUCCESS
            })
        }
    } catch (err) {
        dispatch({
            type: REGISTER_FAIL
        })
    }
}

export const logout = () => async dispatch => {
    const config = {
        headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
    }

    const body = JSON.stringify({
        'withCredentials': true
    })

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/accounts/logout/`, body, config)

        if (res.data.success) {
            dispatch({
                type : LOGOUT_SUCCESS,
            })

        } else {
            dispatch({
                type: LOGOUT_FAIL
            })
        }
    } catch (err) {
        dispatch({
            type: LOGOUT_FAIL
        })
    }
}

export const delete_account = () => async dispatch => {
    const config = {
        headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
    }

    const body = JSON.stringify({
        'withCredentials': true
    })

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/accounts/delete/`, config, body)

        if(res.data.success){
            dispatch({
                type: DELETE_USER_SUCCESS
            })
        } else{
            dispatch({
                type: DELETE_USER_FAIL
            })
        }
    } catch (err) {
        dispatch({
            type: DELETE_USER_FAIL
        })
    }
}