import {
    EST_DATA_SUCCESS,
    EST_DATA_FAIL,
    EST_UPDATE_SUCCESS,
    EST_UPDATE_FAIL
} from '../actions/types'

const initialState = {
    id: '',
    Plantation_Name: '',
    Division_Name: '',
    Year: '',
    Month: '',
    Estimate_Day: '',
    Hectare: ''
}

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case EST_DATA_SUCCESS:
        case EST_UPDATE_SUCCESS:
            return {
                ...state,
                id: payload.id,
                Plantation_Name: payload.Plantation_Name,
                Division_Name: payload.Division_Name,
                Year: payload.Year,
                Month: payload.Month,
                Estimate_Day: payload.Estimate_Day,
                Hectare: payload.Hectare
            }
        case EST_DATA_FAIL:
        case EST_UPDATE_FAIL:
            return {
                ...state,
                id: '',
                Plantation_Name: '',
                Division_Name: '',
                Year: '',
                Month: '',
                Estimate_Day: '',
                Hectare: ''
            }
        case EST_DATA_FAIL:
        case EST_UPDATE_FAIL:
            return {
                ...state
            }
        default:
            return state
    }
}

