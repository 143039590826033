import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Navigate, Link } from 'react-router-dom'
import axios from 'axios'
import { Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { BiSearch } from "react-icons/bi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const PER_PAGE = 10

function Estimate({
    first_name_global,
    last_name_global,
    company_global,
    role_global,
    estate_global,
    employee_id_global
}) {

    const [getDatas, setGetDatas] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    // const [searchDate, setSearchDate] = useState("")
    const [selectedMonth, setSelectedMonth] = useState("")
    const [selectedYear, setSelectedYear] = useState("")
    let months = [
        {
            key: 1, month: "January"
        },
        {
            key: 2, month: "February"
        },
        {
            key: 3, month: "March"
        },
        {
            key: 4, month: "April"
        },
        {
            key: 5, month: "May"
        },
        {
            key: 6, month: "June"
        },
        {
            key: 7, month: "July"
        },
        {
            key: 8, month: "August"
        },
        {
            key: 9, month: "September"
        },
        {
            key: 10, month: "October"
        },
        {
            key: 11, month: "November"
        },
        {
            key: 12, month: "December"
        },
    ]

    const currentDate = new Date()
    const currentYear = moment(currentDate).year() - 5
    const currentYearFive = moment(currentDate).year() + 5
    let yearRange = []

    for (let i = currentYear; i < currentYearFive; i++) {
        const element = i;
        yearRange.push(element)
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBdivEstNew/`)
            .then((response) => {
                setGetDatas(response.data)
            })
            .catch(error => console.error(`Error: ${error}`))
    }, [])

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage)
    }

    function handleSelectedYearChange(event) {
        setSelectedYear(event.target.value)
    }

    function handleSelectedMonthChange(event) {
        setSelectedMonth(event.target.value)
    }

    const offset = currentPage * PER_PAGE
    const sortdata = getDatas.sort((a, b) => b.id - a.id)
    const currentPageData = sortdata
        .slice(offset, offset + PER_PAGE)

    const pageCount = Math.ceil(getDatas.length / PER_PAGE)

    const filteredData = getDatas.filter(data => data.Year === Number(selectedYear) && data.Month === Number(selectedMonth)).sort((a, b) => b.id - a.id)
    const currentPageFilterData = filteredData.slice(offset, offset + PER_PAGE)

    const pageCountFilter = Math.ceil(filteredData.length / PER_PAGE)

    console.log(filteredData)
    console.log(selectedYear)
    console.log(selectedMonth)

    return (
        <div className='container-fluid content-container mt-5'>
            {
                role_global === 'Clerk' ? (
                    <Link to="/est_form" className='btn btn-primary'>Add Estimate Data</Link>
                ) : role_global === "Assistant Manager" ? (
                    <Link to="/est_form_am" className='btn btn-primary'>Add Estimate Data</Link>
                ) : role_global === "Manager" ? (
                    <Link to="/est_form_m" className='btn btn-primary'>Add Estimate Data</Link>
                ) : null
            }
            <h6 className="text-left mb-3 mt-3">Filter By:</h6>
            <div className='d-flex'>
                <div className="form-group">
                    <label className='form-label mt-3' htmlFor='division'>Year:</label>
                    <select className="form-input-summary" value={selectedYear} onChange={handleSelectedYearChange}>
                        <option value="">Choose...</option>
                        {
                            yearRange.map((x) => {
                                return (
                                    <option value={x}>{x}</option>
                                )
                            })
                        }
                    </select>
                </div>

                <div className="form-group">
                    <label className='form-label mt-3' htmlFor='division'>Month:</label>
                    <select className="form-input-summary" value={selectedMonth} onChange={handleSelectedMonthChange}>
                        <option value="">Choose...</option>
                        {
                            months.map((x) => {
                                return (
                                    <option value={x.key}>{x.month}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div className='container-fluid mt-5'>
                <Table pagination={true}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Plantation_Name</th>
                            <th>Division_Name</th>
                            <th>Year</th>
                            <th>Month</th>
                            <th>Estimate Weight Per Day</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            selectedMonth === '' & selectedYear === '' ? (
                                currentPageData.map((getData) => {
                                    return (
                                        <tr>
                                            <td>{getData.id}</td>
                                            <td>{getData.Plantation_Name}</td>
                                            <td>{getData.Division_Name}</td>
                                            <td>{getData.Year}</td>
                                            <td>{getData.Month}</td>
                                            <td>{getData.Estimate_Day}</td>
                                            {
                                                role_global === "Clerk" ? (
                                                    <td><Link className='btn btn-primary' to={`/est_update/${getData.id}`}>Edit</Link></td>
                                                ) : role_global === "Assistant Manager" ? (
                                                    <td><Link className='btn btn-primary' to={`/est_update_am/${getData.id}`}>Edit</Link></td>
                                                ) : role_global === "Manager" ? (
                                                    <td><Link className='btn btn-primary' to={`/est_update_m/${getData.id}`}>Edit</Link></td>
                                                ) : null
                                            }
                                        </tr>
                                    )
                                })
                            ) : (
                                currentPageFilterData.map((getData) => {
                                    return (
                                        <tr>
                                            <td>{getData.id}</td>
                                            <td>{getData.Plantation_Name}</td>
                                            <td>{getData.Division_Name}</td>
                                            <td>{getData.Year}</td>
                                            <td>{getData.Month}</td>
                                            <td>{getData.Estimate_Day}</td>
                                            {
                                                role_global === "Clerk" ? (
                                                    <td><Link className='btn btn-primary' to={`/est_update/${getData.id}`}>Edit</Link></td>
                                                ) : role_global === "Assistant Manager" ? (
                                                    <td><Link className='btn btn-primary' to={`/est_update_am/${getData.id}`}>Edit</Link></td>
                                                ) : role_global === "Manager" ? (
                                                    <td><Link className='btn btn-primary' to={`/est_update_m/${getData.id}`}>Edit</Link></td>
                                                ) : null
                                            }
                                        </tr>
                                    )

                                })
                            )
                        }
                    </tbody>
                </Table>
            </div>
            {
                selectedMonth === "" & selectedYear === "" ? (
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                ) : (
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCountFilter}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                )
            }
            {/* <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        containerClassName={"pagination"}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        breakClassName={'page-item'}
        breakLinkClassName={'page-link'}
        activeClassName={'active'}
      /> */}
        </div >

    )
}

const mapStateToProps = state => ({
    first_name_global: state.profile.first_name,
    last_name_global: state.profile.last_name,
    company_global: state.profile.company,
    role_global: state.profile.role,
    estate_global: state.profile.estate,
    employee_id_global: state.profile.employee_id
})

export default connect(mapStateToProps, {})(Estimate)