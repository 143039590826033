import Cookies from 'js-cookie'
import axios from 'axios'
import {
    EMPLOYEE_DATA_SUCCESS,
    EMPLOYEE_DATA_FAIL,
    EMPLOYESS_UPDATE_SUCCESS,
    EMPLOYESS_UPDATE_FAIL,
    EMPLOYESS_DELETE_SUCCESS,
    EMPLOYESS_DELETE_FAIL,

} from './types'
import { toast } from 'react-toastify'


export const add_employee_data = (
    Employee_id,
    Name,
    Role,
    Plantation
) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
    }

    const body = JSON.stringify({
        'withCredentials': true,
        Employee_id,
        Name,
        Role,
        Plantation
    })

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/LTSB/LTSBlistemp/`, body, config)

        if (!res.data.Employee_id) {
            dispatch({
                type: EMPLOYEE_DATA_FAIL,
            })
            toast(res.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else if (res.data.Employee_id) {
            dispatch({
                type: EMPLOYEE_DATA_SUCCESS,
                payload: res.data
            })
            toast(res.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        } else {
            dispatch({
                type: EMPLOYEE_DATA_FAIL,
            })
        }
    } catch (err) {
        dispatch({
            type: EMPLOYEE_DATA_FAIL
        })
    }
}

export const update_employee_data = (
    Employee_id,
    Name,
    Role
) => async dispatch => {
    const config = {
        headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken' : Cookies.get('csrftoken')
        }
    }

    const body = JSON.stringify({
        'withCredentials' : true,
        Employee_id,
        Name,
        Role
    })


    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/LTSB/LTSBlistemp/id=${Employee_id}/`, body, config)

        if (res) {
            dispatch({
                type: EMPLOYESS_UPDATE_SUCCESS,
                payload: res.data
            })
            toast(res.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        } else {
            dispatch({
                type: EMPLOYESS_UPDATE_FAIL
            })
        }
    } catch (err) {
        dispatch({
            type: EMPLOYESS_UPDATE_FAIL
        })
    }
}

export const delete_employee_data = (
    Employee_id,
    Name,
    Role
) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
    }

    const body = JSON.stringify({
        'withCredentials': true,
        Employee_id,
        Name,
        Role
    })

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/LTSB/LTSBlistemp/id=${Employee_id}/`, config, body)

        if (res) {
            dispatch({
                type: EMPLOYESS_DELETE_SUCCESS
            })
            toast(res.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        } else {
            dispatch({
                type: EMPLOYESS_DELETE_FAIL
            })
        }
    } catch (err) {
        dispatch({
            type: EMPLOYESS_DELETE_FAIL
        })
    }
}