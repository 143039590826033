export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';


export const LOAD_USER_PROFLE_SUCCESS = 'LOAD_USER_PROFLE_SUCCESS';
export const LOAD_USER_PROFLE_FAIL = 'LOAD_USER_PROFLE_FAIL';
export const INPUT_DATA_UPDATE_SUCCESS = 'INPUT_DATA_UPDATE_SUCCESS'
export const INPUT_DATA_UPDATE_FAIL = 'INPUT_DATA_UPDATE_FAIL'
export const EMPLOYEE_DATA_SUCCESS = 'EMPLOYEE_DATA_SUCCESS'
export const EMPLOYEE_DATA_FAIL = 'EMPLOYEE_DATA_FAIL'
export const UPDATE_DATA_SUCCESS = 'UPDATE_DATA_SUCCESS'
export const UPDATE_DATA_FAIL = 'UPDATE_DATA_FAIL'
export const DELETE_DATA_SUCCESS = 'DELETE_DATA_SUCCESS'
export const DELETE_DATA_FAIL = 'DELETE_DATA_FAIL'
export const EMPLOYESS_UPDATE_SUCCESS = 'EMPLOYESS_UPDATE_SUCCESS'
export const EMPLOYESS_UPDATE_FAIL = 'EMPLOYESS_UPDATE_FAIL'
export const EMPLOYESS_DELETE_SUCCESS = 'EMPLOYESS_DELETE_SUCCESS'
export const EMPLOYESS_DELETE_FAIL = 'EMPLOYESS_DELETE_FAIL'

export const EST_DATA_SUCCESS = 'EST_DATA_SUCCESS'
export const EST_DATA_FAIL = 'EST_DATA_FAIL'
export const EST_UPDATE_SUCCESS = 'EST_UPDATE_SUCCESS'
export const EST_UPDATE_FAIL = 'EST_UPDATE_FAIL'
export const EST_DELETE_SUCCESS = 'EST_DELETE_SUCCESS'
export const EST_DELETE_FAIL = 'EST_DELETE_FAIL'


export const UPDATE_USER_PROFLE_SUCCESS = 'UPDATE_USER_PROFLE_SUCCESS';
export const UPDATE_USER_PROFLE_FAIL = 'UPDATE_USER_PROFLE_FAIL';
export const UPDATE_PASSWORD_SUCESS = 'UPDATE_PASSWORD_SUCESS'
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL'

