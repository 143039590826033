import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './hocs/Layout'
import Register from './containers/Register'
import Login from './containers/Login'
import Profile from './containers/Profile'
import PrivateRoute from './hocs/PrivateRoute'
import PrivateRouteAM from './hocs/PrivateRouteAM'
import PrivateRouteM from './hocs/PrivateRouteM'
import InputData from './containers/InputData';
import InputDataClerk from './containers/InputDataClerk'
import AddInputData from './containers/InputDataForm';
import AddInputDataClerk from './containers/InputDataFormClerk'
import UpdateForm from './containers/UpdateForm';
import UpdateFormClerk from './containers/UpdateFormClerk'
import Dashboard from './containers/Dashboard';
import DashboardClerk from './containers/DashboardClerk';
import AddEmployeeData from './containers/EmployeeForm'
import Employee from './containers/Employee';
import Summary from './containers/Summary';
import SummaryClerk from './containers/SummaryClerk';
import EmployeeUpdateForm from './containers/EmployeeUpdateForm';
import AddEstimateData from './containers/EstimateForm'
import Estimate from './containers/Estimate';
import UpdateEstimateData from './containers/EstimateUpdateForm'
import Report from './containers/Report';
import MachineryDashboard from './containers/MachineryDashboard'
import MachineryList from './containers/MachineryList';

import { Provider } from 'react-redux'
import store from './store'
import "./static/navbar.css"
import "./static/main.css"
import EstimateUpdateForm from './containers/EstimateUpdateForm';
import { Navigate } from 'react-router-dom';


const App = () => (

    <Provider store={store}>
        <Router>
            <Layout />
            <Routes>
                <Route path="/register" element={<Register />} />
                <Route path="/" element={<Login />} />

                <Route path="/dashboard" element={
                    <PrivateRoute>
                        <DashboardClerk />
                    </PrivateRoute>
                } />
                <Route path="/dashboard_am" element={
                    <PrivateRouteAM>
                        <Dashboard />
                    </PrivateRouteAM>
                } />
                <Route path="/dashboard_m" element={
                    <PrivateRouteM>
                        <Dashboard />
                    </PrivateRouteM>
                } />

                {/* -------------------------------------------------------------------- */}


                <Route path="/profile" element={
                    <PrivateRoute>
                        <Profile />
                    </PrivateRoute>
                } />
                <Route path="/profile_am" element={
                    <PrivateRouteAM>
                        <Profile />
                    </PrivateRouteAM>
                } />
                <Route path="/profile_m" element={
                    <PrivateRouteM>
                        <Profile />
                    </PrivateRouteM>
                } />

                {/* -------------------------------------------------------------------- */}

                <Route path="/input_data" element={
                    <PrivateRoute>
                        <InputDataClerk />
                    </PrivateRoute>
                } />
                <Route path="/input_form" element={
                    <PrivateRoute>
                        <AddInputDataClerk />
                    </PrivateRoute>
                } />
                <Route path="/update_form/:DO_NO" element={
                    <PrivateRoute>
                        <UpdateFormClerk />
                    </PrivateRoute>
                } />

                <Route path="/input_data_am" element={
                    <PrivateRouteAM>
                        <InputData />
                    </PrivateRouteAM>
                } />
                <Route path="/input_form_am" element={
                    <PrivateRouteAM>
                        <AddInputData />
                    </PrivateRouteAM>
                } />
                <Route path="/update_form_am/:DO_NO" element={
                    <PrivateRouteAM>
                        <UpdateForm />
                    </PrivateRouteAM>
                } />

                <Route path="/input_data_m" element={
                    <PrivateRouteM>
                        <InputData />
                    </PrivateRouteM>
                } />
                <Route path="/input_form_m" element={
                    <PrivateRouteM>
                        <AddInputData />
                    </PrivateRouteM>
                } />
                <Route path="/update_form_m/:DO_NO" element={
                    <PrivateRouteM>
                        <UpdateForm />
                    </PrivateRouteM>
                } />

                {/* -------------------------------------------------------------------- */}


                {/* <Route path="/emp_form" element={
                    <PrivateRoute>
                        <AddEmployeeData />
                    </PrivateRoute>
                } />
                <Route path="/emp" element={
                    <PrivateRoute>
                        <Employee />
                    </PrivateRoute>
                } />
                <Route path="/emp_update/:id" element={
                    <PrivateRoute>
                        <EmployeeUpdateForm />
                    </PrivateRoute>
                } /> */}

                <Route path="/emp_form_am" element={
                    <PrivateRouteAM>
                        <AddEmployeeData />
                    </PrivateRouteAM>
                } />
                <Route path="/emp_am" element={
                    <PrivateRouteAM>
                        <Employee />
                    </PrivateRouteAM>
                } />
                <Route path="/emp_update_am/:id" element={
                    <PrivateRouteAM>
                        <EmployeeUpdateForm />
                    </PrivateRouteAM>
                } />

                <Route path="/emp_form_m" element={
                    <PrivateRouteM>
                        <AddEmployeeData />
                    </PrivateRouteM>
                } />
                <Route path="/emp_m" element={
                    <PrivateRouteM>
                        <Employee />
                    </PrivateRouteM>
                } />
                <Route path="/emp_update_m/:id" element={
                    <PrivateRouteM>
                        <EmployeeUpdateForm />
                    </PrivateRouteM>
                } />

                {/* -------------------------------------------------------------------- */}

                {/* <Route path="/est" element={
                    <PrivateRoute>
                        <Estimate />
                    </PrivateRoute>
                } />
                <Route path="/est_form" element={
                    <PrivateRoute>
                        <AddEstimateData />
                    </PrivateRoute>
                } />
                <Route path="/est_update/:id" element={
                    <PrivateRoute>
                        <EstimateUpdateForm />
                    </PrivateRoute>
                } /> */}

                <Route path="/est_am" element={
                    <PrivateRouteAM>
                        <Estimate />
                    </PrivateRouteAM>
                } />
                <Route path="/est_form_am" element={
                    <PrivateRouteAM>
                        <AddEstimateData />
                    </PrivateRouteAM>
                } />
                <Route path="/est_update_am/:id" element={
                    <PrivateRouteAM>
                        <EstimateUpdateForm />
                    </PrivateRouteAM>
                } />

                <Route path="/est_m" element={
                    <PrivateRouteM>
                        <Estimate />
                    </PrivateRouteM>
                } />
                <Route path="/est_form_m" element={
                    <PrivateRouteM>
                        <AddEstimateData />
                    </PrivateRouteM>
                } />
                <Route path="/est_update_m/:id" element={
                    <PrivateRouteM>
                        <EstimateUpdateForm />
                    </PrivateRouteM>
                } />

                {/* -------------------------------------------------------------------- */}


                <Route path="/summary" element={
                    <PrivateRoute>
                        <SummaryClerk />
                    </PrivateRoute>
                } />
                <Route path="/summary_am" element={
                    <PrivateRouteAM>
                        <Summary />
                    </PrivateRouteAM>
                } />
                <Route path="/summary_m" element={
                    <PrivateRouteM>
                        <Summary />
                    </PrivateRouteM>
                } />

                {/* -------------------------------------------------------------------- */}

                {/* <Route path="/report" element={
                    <PrivateRoute>
                        <Report />
                    </PrivateRoute>
                } /> */}
                <Route path="/report_am" element={
                    <PrivateRouteAM>
                        <Report />
                    </PrivateRouteAM>
                } />
                <Route path="/report_m" element={
                    <PrivateRouteM>
                        <Report />
                    </PrivateRouteM>
                } />

                {/* -------------------------------------------------------------------- */}


                {/* <Route path="/mc_dashboard" element={
                    <PrivateRoute>
                        <MachineryDashboard />
                    </PrivateRoute>
                } />

                <Route path="/mc_list" element={
                    <PrivateRoute>
                        <MachineryList />
                    </PrivateRoute>
                } /> */}

                <Route render={() => <Navigate to={{ pathname: "/" }} />} />
            </Routes>
        </Router>
    </Provider>


);

export default App