import Cookies from 'js-cookie'
import axios from 'axios'
import {
    UPDATE_PASSWORD_SUCESS,
    UPDATE_PASSWORD_FAIL
} from './types'
import { toast } from 'react-toastify'

export const update_password = (old_pwd, new_pwd, confirm_new_pwd) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
    }

    const body = JSON.stringify({
        'withCredentials': true,
        old_pwd, 
        new_pwd, 
        confirm_new_pwd
    })

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/profile/reset-password/`, body, config)

        if (res.data.error) {
            dispatch({
                type: UPDATE_PASSWORD_FAIL,
                payload: res.data
            })
            toast.error(res.data.error, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            dispatch({
                type: UPDATE_PASSWORD_SUCESS,
                payload: res.data
            })
            toast.success(res.data.success, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setTimeout(() => {
                window.location.reload()
            }, 3000)
        }
    } catch (err) {
        dispatch({
            type: UPDATE_PASSWORD_FAIL
        })
    }
}

