import React from 'react'
import {Route, Navigate} from 'react-router-dom'
import {connect} from 'react-redux'

function PrivateRoute({isAunthenticated, isRole, children}) {
  return isAunthenticated && (isRole === "Clerk" || isRole === 'Senior Asisstant Manager') ? children: <Navigate to="/"/>
}

const mapStateToProps = state => ({
    isAunthenticated : state.auth.isAuthenticated,
    isRole : state.profile.role
})

export default connect(mapStateToProps, {})(PrivateRoute)