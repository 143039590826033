import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Navigate, Link } from 'react-router-dom'
import axios from 'axios'
import { Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { BiSearch } from "react-icons/bi";
import DayJS from 'react-dayjs'

const PER_PAGE = 10

function InputDataClerk({
  first_name_global,
  last_name_global,
  company_global,
  role_global,
  estate_global,
  employee_id_global
}) {

  const [getDatas, setGetDatas] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [searchDate, setSearchDate] = useState("")
  const [searchDate2, setSearchDate2] = useState("")

  const minDate = Date.parse(searchDate)
  const maxDate = Date.parse(searchDate2)

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBffbreceipt/`)
      .then((response) => {
        setGetDatas(response.data)
      })
      .catch(error => console.error(`Error: ${error}`))
  }, [])


  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage)
  }

  const offset = currentPage * PER_PAGE

  const currentData = getDatas.filter(x => x.Mill !== 'NONE' && x.Clerk !== 'NONE' && x.Driver !== 'NONE' && x.Vehicle_No !== 'NONE' && x.Block !== 'NONE' && x.Plantation === estate_global)
  const currentPageData = currentData
    .slice(offset, offset + PER_PAGE)



  const pageCount = Math.ceil(currentData.length / PER_PAGE)

  const filteredData = currentData.filter(x => Date.parse(x.Date) >= minDate && Date.parse(x.Date) <= maxDate && x.Plantation === estate_global)
  const currentPageFilterData = filteredData.slice(offset, offset + PER_PAGE)

  const pageCountFilter = Math.ceil(filteredData.length / PER_PAGE)

  return (
    <div className='container-fluid content-container mt-5'>
      {
        role_global === 'Clerk' ? (
          <Link to="/input_form" className='btn btn-primary'>New data</Link>
        ) : null
      }

      <h6 className="text-left mb-3 mt-3">Filter By:</h6>
      <div className='form-group d-flex'>
        <div>
          <label htmlFor="date-filter">From:</label>
          <input type="date" placeholder='Date' className='form-input-summary' name="start-date" value={searchDate} onChange={event => { setSearchDate(event.target.value) }} />
        </div>
        <div>
          <label htmlFor="date-filter">To:</label>
          <input type="date" placeholder='Date' className='form-input-summary' name="end-date" value={searchDate2} onChange={event => { setSearchDate2(event.target.value) }} />
        </div>
      </div>
      <div className='container-fluid mt-5'>
        <Table pagination={true}>
          <thead>
            <tr>
              <th>DO Number</th>
              <th>Date</th>
              <th>Plantation</th>
              <th>Division</th>
              <th>Mill</th>
              <th>Net Weight(MT)</th>
            </tr>
          </thead>
          <tbody>
            {
              searchDate === "" & searchDate2 === "" ? (
                currentPageData.map((getData) => {
                  return (
                    <tr>
                      <td>{getData.DO_NO}</td>
                      <td>{getData.Date}</td>
                      <td>{getData.Plantation}</td>
                      <td>{getData.Division}</td>
                      <td>{getData.Mill}</td>
                      <td>{getData.Today_NetWeight}</td>
                      {
                        getData.Clerk === employee_id_global ? (
                          <td><Link className='btn btn-primary' to={`/update_form/${getData.DO_NO}`}>Edit</Link></td>
                        ) : (
                          <td><Link className='btn btn-primary' to={`/update_form/${getData.DO_NO}`}>View</Link></td>
                        )
                      }

                    </tr>
                  )
                })
              ) : (
                currentPageFilterData.map((getData) => {
                  return (
                    <tr>
                      <td>{getData.DO_NO}</td>
                      <td>{getData.Date}</td>
                      <td>{getData.Plantation}</td>
                      <td>{getData.Division}</td>
                      <td>{getData.Mill}</td>
                      <td>{getData.Today_NetWeight}</td>
                      {
                        getData.Clerk === employee_id_global ? (
                          <td><Link className='btn btn-primary' to={`/update_form/${getData.DO_NO}`}>Edit</Link></td>
                        ) : (
                          <td><Link className='btn btn-primary' to={`/update_form/${getData.DO_NO}`}>View</Link></td>
                        )
                      }
                    </tr>
                  )

                })
              )
            }
          </tbody>
        </Table>
      </div>
      {
        searchDate === "" && searchDate2 === "" ? (
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            containerClassName={"pagination"}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            activeClassName={'active'}
          />
        ) : (
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCountFilter}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            containerClassName={"pagination"}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            activeClassName={'active'}
          />
        )
      }

    </div >

  )
}

const mapStateToProps = state => ({
  first_name_global: state.profile.first_name,
  last_name_global: state.profile.last_name,
  company_global: state.profile.company,
  role_global: state.profile.role,
  estate_global: state.profile.estate,
  employee_id_global: state.profile.employee_id
})

export default connect(mapStateToProps, {})(InputDataClerk)