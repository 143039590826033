import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { update_profile } from '../actions/profile'
import { delete_account } from '../actions/auth'
import { update_password } from '../actions/passwordReset'
import axios from 'axios'
import { ToastContainer } from 'react-toastify'
import '../../node_modules/react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom'

function Profile({
  delete_account,
  update_password,
  update_profile,
  first_name_global,
  last_name_global,
  company_global,
  role_global,
  estate_global,
  employee_id_global,
  success_change,
}) {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    company: '',
    role: '',
    estate: '',
    employee_id: ''
  })

  let navigate = useNavigate()

  const { first_name, last_name, company, role, estate, employee_id } = formData

  const [roleOri, setRoleOri] = useState([])
  const [estateOri, setEstateOri] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const [oldPwd, setOldPwd] = useState('')
  const [newPwd, setNewPwd] = useState('')
  const [confirmNewPwd, setConfirmNewPwd] = useState('')

  useEffect(() => {
    setFormData({
      first_name: first_name_global,
      last_name: last_name_global,
      company: company_global,
      role: role_global,
      estate: estate_global,
      employee_id: employee_id_global
    })
  }, [first_name_global])

  useEffect(async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/accounts/role/`)
      .then((response) => {
        setRoleOri(response.data)
        setIsLoading(false)
      })
      .catch(error => console.error(`Error: ${error}`))
  }, [])

  useEffect(async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/accounts/estate/`)
      .then((response) => {
        setEstateOri(response.data)
        setIsLoading(false)
      })
      .catch(error => console.error(`Error: ${error}`))
  }, [])

  function handleOldPassword(event) {
    setOldPwd(event.target.value)
  }

  function handleNewPassword(event) {
    setNewPwd(event.target.value)
  }

  function handleConfirmNewPassword(event) {
    setConfirmNewPwd(event.target.value)
  }

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

  const onSubmit = e => {
    e.preventDefault();
    update_profile(first_name, last_name, company, role, estate, employee_id)
  }

  const changePassword = e => {
    e.preventDefault();
    update_password(oldPwd, newPwd, confirmNewPwd)
  }

  return (
    <div className='container' id="content">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <h1 className='mt-3 mb-3'>Welcome to your user dashboard</h1>
      <p className='mt-3 mb-3'>Update your user profile below:</p>
      <form onSubmit={e => onSubmit(e)}>
        <div className="form-group">
          <label className='form-label mt-3' htmlFor='first_name'>First Name</label>
          <input className='form-control' type='text' name='first_name' onChange={e => onChange(e)} value={first_name} placeholder={`${first_name_global}`} />
          <label className='form-label mt-3' htmlFor='last_name'>Last Name</label>
          <input className='form-control' type='text' name='last_name' onChange={e => onChange(e)} value={last_name} placeholder={`${last_name_global}`} />
          <label className='form-label mt-3' htmlFor='company'>Company</label>
          <input className='form-control' type='text' name='company' onChange={e => onChange(e)} value={company} placeholder={`${company_global}`} />
          <label className='form-label mt-3' htmlFor='role'>Role</label>
          {
            roleOri.filter(x => x.key === role).map((x) => {
              return (
                <input className='form-control' type='text' name='role' value={x.value} disabled="disabled" isLoading={isLoading} />
              )
            })
          }
          {/* <label className='form-label mt-3' htmlFor='role'>Estate</label>
          {
            estateOri.filter(x => x.key === role).map((x) => {
              return (
                <input className='form-control' type='text' name='role' value={x.value} disabled="disabled" isLoading={isLoading} />
              )
            })
          } */}
          <label className='form-label mt-3' htmlFor='first_name'>Estate</label>
          <input className='form-control' type='text' name='first_name' onChange={e => onChange(e)} value={estate} placeholder={`${estate_global}`} disabled />
          <label className='form-label mt-3' htmlFor='first_name'>Employee ID</label>
          <input className='form-control' type='text' name='first_name' onChange={e => onChange(e)} value={employee_id} placeholder={`${employee_id_global}`} disabled />
        </div>
        <button className="btn btn-primary mt-3" type='submit'>Update Profile</button>
      </form>


      {/* <p className='mt-5'>
        Click the button below to delete your account
      </p>
      <a href="#!" className="btn btn-danger" onClick={delete_account}>Delete Account</a> */}


      <div className='container my-3'>
        <form>
          <div className="form-group">
            <label className='form-label mt-3' htmlFor='old_pwd'>Old Password</label>
            <input className='form-control' type='password' name='old_pwd' onChange={handleOldPassword} value={oldPwd} placeholder="Old Password" />
            <label className='form-label mt-3' htmlFor='new_pwd'>New Password</label>
            <input className='form-control' type='password' name='new_pwd' onChange={handleNewPassword} value={newPwd} placeholder="New Password" />
            <label className='form-label mt-3' htmlFor='confirm_new_pwd'>Confirm New Password</label>
            <input className='form-control' type='password' name='confirm_new_pwd' onChange={handleConfirmNewPassword} value={confirmNewPwd} placeholder="Confirm New Password" />
          </div>
          <button className="btn btn-primary mt-3" type='submit' onClick={e => changePassword(e)}>Change Password</button>
        </form>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  first_name_global: state.profile.first_name,
  last_name_global: state.profile.last_name,
  company_global: state.profile.company,
  role_global: state.profile.role,
  estate_global: state.profile.estate,
  employee_id_global: state.profile.employee_id,
  success_change: state.password,
})

export default connect(mapStateToProps, {
  update_profile,
  delete_account,
  update_password,
})(Profile)