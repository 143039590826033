import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { add_input_data } from '../actions/inputData'
import axios from 'axios'
import Select from 'react-select'
import { ToastContainer } from 'react-toastify'
import '../../node_modules/react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom'

function AddInputData({
  add_input_data,
  first_name_global,
  last_name_global,
  company_global,
  role_global,
  estate_global,
  employee_id_global
}) {
  let navigate = useNavigate()
  const [divs, setDivs] = useState([])
  const [estates, setEstate] = useState([])
  const [mills, setMills] = useState([])
  const [emps, setEmps] = useState([])
  const [block, setBlock] = useState([])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBlistdiv/`)
      .then((response) => {
        setDivs(response.data)
      })
      .catch(error => console.error(`Error: ${error}`))
  }, [])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBlistplant/`)
      .then((response) => {
        setEstate(response.data)
      })
      .catch(error => console.error(`Error: ${error}`))
  }, [])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBlistmill/`)
      .then((response) => {
        setMills(response.data)
      })
      .catch(error => console.error(`Error: ${error}`))
  }, [])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBlistemp/`)
      .then((response) => {
        setEmps(response.data)
      })
      .catch(error => console.error(`Error: ${error}`))
  }, [])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/LTSB/LTSBlistblock/`)
      .then((response) => {
        setBlock(response.data)
      })
      .catch(error => console.error(`Error: ${error}`))
  }, [])

  const filteredDataClerks = emps.filter(data => data.Role === 'Clerk')
  const filteredDataDrivers = emps.filter(data => data.Role === 'Driver')
  const filterBlock = block.filter(x => x.Name !== "NONE")
  const filterMill = mills.filter(x => x.ID !== "NONE")


  const [selectedDivision, setSelectedDivision] = useState('Choose...'); //default value
  const [selectedBlock, setSelectedBlock] = useState('Choose...'); //default value
  const [selectedEstate, setSelectedEstate] = useState('Choose...'); //default value
  const [selectedMill, setSelectedMill] = useState('Choose...'); //default value
  const [selectedClerks, setSelectedClerks] = useState(employee_id_global)
  const [selectedDrivers, setSelectedDrivers] = useState('Choose...')
  const [doNumber, setDoNumber] = useState('')
  const [selectedDate, setSelectedDate] = useState('')
  const [vehicleNumber, setVehicleNumber] = useState('')
  const [timeIn, setTimeIn] = useState('')
  const [timeOut, setTimeOut] = useState('')
  const [firstWeight, setFirstWeight] = useState('')
  const [secondWeight, setSecondWeight] = useState('')
  const [netWeight, setNetWeight] = useState('')
  const [remarks, setRemarks] = useState('')

  function handleClerkChange(event) {
    setSelectedClerks(event.target.value)
  }

  function handleDriversChange(event) {
    setSelectedDrivers(event.target.value)
  }

  function handleDOChange(event) {
    setDoNumber(event.target.value)
  }

  function handleVNChange(event) {
    setVehicleNumber(event.target.value)
  }

  function handleRemarksChange(event) {
    setRemarks(event.target.value)
  }

  function handleTIChange(event) {
    setTimeIn(event.target.value)
  }

  function handleTOChange(event) {
    setTimeOut(event.target.value)
  }

  function handleSelectEstateChange(event) {
    setSelectedEstate(event.target.value)
  }

  function handleSelectDivisionChange(event) {
    setSelectedDivision(event.target.value);
  }

  function handleSelectedBlockChange(event) {
    setSelectedBlock(event.target.value);
  }

  function handleSelectMillChange(event) {
    setSelectedMill(event.target.value);
  }

  function handleFirstWeightChange(event) {
    setFirstWeight(event.target.value)
  }

  function handleSecondWeightChange(event) {
    setSecondWeight(event.target.value)
  }

  function handleNetWeightChange(event) {
    setNetWeight(event.target.value)
  }

  function handleSelectDateChange(event) {
    setSelectedDate(event.target.value)
  }

  const parts = "TEST"

  const onSubmit = e => {
    e.preventDefault();
    add_input_data(doNumber, selectedDate, selectedEstate, selectedDivision, parts, selectedBlock, selectedMill, selectedClerks, selectedDrivers, vehicleNumber, timeIn, timeOut, firstWeight, secondWeight, netWeight, remarks)
    // add_input_data(doNumber, selectedDate, selectedEstate, selectedDivision, selectedBlock, selectedMill, selectedDrivers, vehicleNumber, timeIn, timeOut, firstWeight, secondWeight, netWeight, remarks)
    setDoNumber('')
    setSelectedDate('')
    setSelectedEstate('')
    setSelectedDivision('')
    setSelectedBlock('')
    setSelectedMill('')
    // setSelectedClerks('')
    setSelectedDrivers('')
    setVehicleNumber('')
    setTimeIn('')
    setTimeOut('')
    setFirstWeight('')
    setSecondWeight('')
    setNetWeight('')
    setRemarks('')
    setTimeout(function () {
      navigate('/input_data')
    }, 2000);
  }

  const cancel = e => {
    e.preventDefault();
    navigate('/input_data')
  }

  return (
    <div className="container mt-3">
      <form onSubmit={e => onSubmit(e)}>
        <div className="w-75 mx-auto shadow p-5">
          <h2 className="text-center mb-4">Add Data</h2>

          <div className="form-group">
            <label className='form-label mt-3' htmlFor='weight'>DO Number</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter DO Number"
              name="do_number"
              value={doNumber}
              onChange={handleDOChange}
            />
          </div>

          <div className="form-group">
            <label className='form-label mt-3' htmlFor='date'>Date</label>
            <input className="form-control" type="date" name="date" placeholder='Choose Date' value={selectedDate} onChange={handleSelectDateChange} />
          </div>

          <div className="form-group">
            <label className='form-label mt-3' htmlFor='estate'>Estate</label>
            <select className="form-control" value={selectedEstate} onChange={handleSelectEstateChange}>
              <option value="">Choose...</option>
              {
                estates.map((estate) => {
                  return (
                    <option value={estate.Name}>{estate.Name}</option>
                  )
                })
              }
            </select>
          </div>

          <div className="form-group">
            <label className='form-label mt-3' htmlFor='division'>Division</label>
            <select className="form-control" value={selectedDivision} onChange={handleSelectDivisionChange}>
              <option value="">Choose...</option>
              {
                divs.map((div) => {
                  return (
                    <option value={div.Name}>{div.Name}</option>
                  )
                })
              }
            </select>
          </div>

          <div className="form-group">
            <label className='form-label mt-3' htmlFor='block'>Block</label>
            <select className="form-control" value={selectedBlock} onChange={handleSelectedBlockChange}>
              <option value="">Choose...</option>
              {
                filterBlock.map((block) => {
                  return (
                    <option value={block.Name}>{block.Name}</option>
                  )
                })
              }
            </select>
          </div>

          <div className="form-group">
            <label className='form-label mt-3' htmlFor='mill'>Mill</label>
            <select className="form-control" value={selectedMill} onChange={handleSelectMillChange}>
              <option value="">Choose...</option>
              {
                filterMill.map((mill) => {
                  return (
                    <option value={mill.ID}>{mill.Name}</option>
                  )
                })
              }
            </select>
          </div>

          <div className="form-group">
            <label className='form-label mt-3' htmlFor='mill'>Clerk</label>
            <select className="form-control" value={selectedClerks} onChange={handleClerkChange} disabled>
              <option value="">Choose...</option>
              {
                filteredDataClerks.filter(data => data.Employee_id === employee_id_global).map((clerk) => {
                    return (
                      <option value={clerk.Employee_id}>{clerk.Name}</option>
                    )
                  
                })
              }
            </select>
          </div>

          {/* <div className="form-group">
            <label className='form-label mt-3' htmlFor='clerk'>Clerk</label>
            <input className="form-control" type="text" name="clerk" placeholder='Clerk' value={employee_id_global} onChange={handleClerkChange} disabled/>
          </div> */}

          <div className="form-group">
            <label className='form-label mt-3' htmlFor='mill'>Driver</label>
            <select className="form-control" value={selectedDrivers} onChange={handleDriversChange}>
              <option value="">Choose...</option>
              {
                filteredDataDrivers.map((driver) => {
                  return (
                    <option value={driver.Employee_id}>{driver.Name}</option>
                  )
                })
              }
            </select>
          </div>

          <div className="form-group">
            <label className='form-label mt-3' htmlFor='weight'>Vehicle Number</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Vehicle Number"
              name="vehicle_number"
              value={vehicleNumber}
              onChange={handleVNChange}
            />
          </div>

          <div className="form-group">
            <label className='form-label mt-3' htmlFor='weight'>Time In</label>
            <input
              type="time"
              className="form-control"
              placeholder="Enter Time In"
              name="time_in"
              value={timeIn}
              onChange={handleTIChange}
            />
          </div>

          <div className="form-group">
            <label className='form-label mt-3' htmlFor='weight'>Time Out</label>
            <input
              type="time"
              className="form-control"
              placeholder="Enter Time Out"
              name="time_out"
              value={timeOut}
              onChange={handleTOChange}
            />
          </div>

          <div className="form-group">
            <label className='form-label mt-3' htmlFor='weight'>First Weight {"(Mt)"}</label>
            <input
              type="float"
              min="1" 
              max="999"
              className="form-control"
              placeholder="Enter First Weight"
              name="first_weight"
              value={firstWeight}
              onChange={handleFirstWeightChange}
            />
          </div>

          <div className="form-group">
            <label className='form-label mt-3' htmlFor='weight'>Second Weight {"(Mt)"}</label>
            <input
              type="float"
              min="1" 
              max="999"
              className="form-control"
              placeholder="Enter Second Weight"
              name="second_weight"
              value={secondWeight}
              onChange={handleSecondWeightChange}
            />
          </div>

          <div className="form-group">
            <label className='form-label mt-3' htmlFor='weight'>Net Weight {"(Mt)"}</label>
            <input
              type="float"
              min="1" 
              max="999"
              className="form-control"
              placeholder="Enter Net Weight"
              name="net_weight"
              value={netWeight}
              onChange={handleNetWeightChange}
            />
          </div>

          <div className="form-group">
            <label className='form-label mt-3' htmlFor='weight'>Remarks</label>
            <textarea
              className="form-control"
              placeholder="Remarks"
              name="remarks"
              value={remarks}
              onChange={handleRemarksChange}
            />
          </div>

          <button className="btn btn-primary btn-block mt-3" type='submit'>Add Data</button>
          <button className="btn btn-dark btn-block mt-3 mx-3" onClick={e => cancel(e)}>Cancel</button>

        </div>
      </form>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

const mapStateToProps = state => ({
  first_name_global: state.profile.first_name,
  last_name_global: state.profile.last_name,
  company_global: state.profile.company,
  role_global: state.profile.role,
  estate_global: state.profile.estate,
  employee_id_global: state.profile.employee_id
})

export default connect(mapStateToProps, { add_input_data, })(AddInputData)